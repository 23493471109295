@keyframes sliderLayerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
* {
  font-family: Montserrat;
}
body {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  // color: var(--text-color1);
}
input {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
select {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
  background-color: #ffffff;
  border-color: var(--color3);
  &:focus {
    color: var(--color2);
    border-color: var(--color-hover3);
    background-color: #ffffff;
  }
}
pre {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
code {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
kbd {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
samp {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
dt {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
}
#cancel-comment-reply-link {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--text-color1);
  &:hover {
    color: var(--color1);
  }
}
.box-item-content {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--text-color1);
}
textarea {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  color: var(--color2);
  background-color: #ffffff;
  border-color: var(--color3);
  &:focus {
    color: var(--color-hover2);
    border-color: var(--color-hover3);
    background-color: #ffffff;
  }
}
.widget_price_filter {
  .price_label {
    font-size: 16px;
    font-weight: 400;
    font-family: Montserrat;
    line-height: 32px;
    letter-spacing: 0px;
    color: var(--text-color1);
  }
  .ui-slider {
    .ui-slider-handle {
      background-color: var(--color1);
    }
  }
  .ui-slider-horizontal {
    .ui-slider-range {
      background-color: var(--color1) !important;
    }
  }
}
.demo-icon-pack {
  span {
    &:after {
      font-size: 16px;
      font-weight: 400;
      font-family: Montserrat;
      line-height: 32px;
      letter-spacing: 0px;
      color: var(--text-color1);
    }
  }
}
.header-login {
  .login-title {
    font-size: 16px;
    font-weight: 400;
    font-family: Montserrat;
    letter-spacing: 0px;
  }
}
.cart-contents {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  letter-spacing: 0px;
}
h1 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 48px;
  line-height: 56px;
}
h2 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 40px;
  line-height: 48px;
}
h3 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 32px;
  line-height: 40px;
}
h4 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 24px;
  line-height: 32px;
}
h5 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 20px;
  line-height: 28px;
}
h6 {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 18px;
  line-height: 26px;
}
.woocommerce-page {
  #et-content {
    .shop_table {
      .product-name {
        > a {
          &:not(.yith-wcqv-button) {
            font-family: Montserrat;
            text-transform: none;
            font-weight: 700;
            letter-spacing: 0px;
            color: #212121;
            color: #212121 !important;
            &:hover {
              color: var(--color1) !important;
            }
          }
        }
      }
    }
  }
}
.woocommerce-Tabs-panel {
  .shop_attributes {
    th {
      font-family: Montserrat;
      text-transform: none;
      font-weight: 700;
      letter-spacing: 0px;
      color: #212121;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 26px;
  }
}
#reply-title {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
  font-size: 24px;
  line-height: 32px;
  color: var(--color1) !important;
}
.et-circle-progress {
  .circle-content {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
  .percent {
    color: #212121;
    font-size: 48px;
    line-height: 48px;
  }
}
.et-timer {
  .timer-count {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    font-size: 48px;
    line-height: 56px;
  }
}
.et-pricing-table {
  .currency {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
  .price {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
  .label {
    background-color: var(--color1);
  }
  .plan {
    &:after {
      background-color: var(--color1);
    }
  }
}
.et-counter {
  .counter {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
}
.et-progress {
  .percent {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
}
.error404-default-subtitle {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
}
.yith-woocompare-widget {
  ul.products-list {
    li {
      .title {
        font-family: Montserrat;
        text-transform: none;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        font-size: 18px;
        line-height: 26px;
        &:hover {
          color: var(--color1);
        }
      }
      .remove {
        &:hover {
          color: var(--color1);
        }
      }
    }
  }
  a.compare {
    background-color: var(--color1);
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    &:hover {
      color: #ffffff !important;
      color: #ffffff !important;
      background-color: #212121;
      background-color: #212121;
    }
  }
  a.clear-all {
    background-color: var(--color1);
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    &:hover {
      color: #ffffff !important;
      color: #ffffff !important;
      background-color: #212121;
      background-color: #212121;
    }
  }
}
.woocommerce-MyAccount-navigation {
  ul {
    li {
      a {
        font-family: Montserrat;
        text-transform: none;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
      }
    }
  }
  li.is-active {
    a {
      color: var(--color1) !important;
    }
  }
}
.woocommerce-tabs {
  .tabs {
    li {
      a {
        font-family: Montserrat;
        text-transform: none;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          background-color: var(--color1);
        }
      }
    }
    li.active {
      a {
        background-color: var(--color1);
      }
    }
  }
}
.product {
  .summary {
    .price {
      font-family: Montserrat;
      text-transform: none;
      font-weight: 700;
      letter-spacing: 0px;
      ins {
        color: var(--color1);
      }
    }
    .woocommerce-product-rating {
      .star-rating {
        color: #ffd800;
      }
    }
  }
  .price {
    color: var(--color1);
  }
  .onsale {
    background-color: var(--color1);
  }
  .post-image-overlay {
    > .overlay-read-more {
      &:hover {
        background-color: var(--color1);
      }
    }
    > .post-image-overlay-content {
      > .overlay-read-more {
        &:hover {
          background-color: var(--color1);
        }
      }
    }
  }
  // .button {
  //   color: var(--text-color1);
  //   font-family: Montserrat;
  //   font-weight: 700;
  //   letter-spacing: 0px;
  //   background-color: var(--button-color1);
  //   transition: all 0.5s ease-in-out;
  //   &:hover {
  //     // color: #ffffff !important;
  //     background-color: var(--button-color-hover1);
  //   }
  // }
  .added_to_cart {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
  }
}
.widget_layered_nav {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        font-size: 18px;
        line-height: 26px;
        &:hover {
          color: var(--color1) !important;
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
    li.chosen {
      > a {
        &:after {
          color: var(--color1) !important;
        }
        &:before {
          box-shadow: inset 0 0 0 2px var(--color1);
        }
      }
    }
  }
}
.widget_nav_menu {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        font-size: 18px;
        line-height: 26px;
        &:hover {
          color: var(--color1) !important;
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
    li.current-menu-item {
      a {
        color: var(--color1);
      }
    }
  }
}
.widget_product_categories {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        font-size: 18px;
        line-height: 26px;
        &:hover {
          color: var(--color1) !important;
        }
      }
    }
    li.current-cat {
      > a {
        color: var(--color1) !important;
      }
    }
  }
}
.widget_categories {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
  }
}
.post-single-navigation {
  a {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    &:hover {
      color: var(--color1) !important;
    }
  }
}
.widget_pages {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
  }
}
.widget_archive {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
  }
}
.widget_meta {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
  }
}
.widget_recent_entries {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
        }
      }
    }
  }
}
.widget_rss {
  ul {
    li {
      a {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
        &:hover {
          color: var(--color1) !important;
        }
      }
    }
  }
}
.widget_icl_lang_sel_widget {
  li {
    a {
      font-family: Montserrat;
      font-weight: 700;
      letter-spacing: 0px;
      color: #212121;
      &:hover {
        color: var(--color1) !important;
      }
    }
  }
}
.recentcomments {
  a {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
    &:hover {
      color: var(--color1) !important;
    }
  }
}
.widget_product_search {
  form {
    button {
      &:before {
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        color: #212121;
      }
    }
    input[type='submit'] {
      + {
        .search-icon {
          color: var(--text-color1) !important;
        }
      }
      &:hover {
        + {
          .search-icon {
            color: var(--text-color1) !important;
          }
        }
      }
    }
  }
}
.page-content-wrap {
  .widget_shopping_cart {
    .cart_list {
      li {
        .remove {
          font-family: Montserrat;
          font-weight: 700;
          letter-spacing: 0px;
          color: #212121;
          &:hover {
            color: var(--color1) !important;
          }
        }
      }
    }
    .cart-product-title {
      a {
        color: #212121;
        &:hover {
          color: var(--color1);
        }
      }
      &:hover {
        a {
          color: var(--color1);
        }
      }
    }
  }
  .woocommerce-mini-cart__buttons {
    > a {
      color: #000000;
      font-family: Montserrat;
      font-weight: 700;
      letter-spacing: 0px;
      background-color: var(--button-color1);
      &:hover {
        color: #ffffff !important;
        background-color: #212121;
      }
    }
  }
}
.widget_et_recent_entries {
  .post-title {
    a {
      color: #212121 !important;
    }
    &:hover {
      a {
        color: var(--color1) !important;
      }
    }
  }
}
.widget_products {
  .product_list_widget {
    > li {
      .product-title {
        a {
          color: #212121 !important;
        }
        &:hover {
          a {
            color: var(--color1) !important;
          }
        }
      }
      > a {
        &:hover {
          .product-title {
            color: var(--color1);
          }
        }
      }
    }
  }
}
.widget_recently_viewed_products {
  .product_list_widget {
    > li {
      .product-title {
        a {
          color: #212121 !important;
        }
        &:hover {
          a {
            color: var(--color1) !important;
          }
        }
      }
      > a {
        &:hover {
          .product-title {
            color: var(--color1);
          }
        }
      }
    }
  }
}
.widget_recent_reviews {
  .product_list_widget {
    > li {
      .product-title {
        a {
          color: #212121 !important;
        }
        &:hover {
          a {
            color: var(--color1) !important;
          }
        }
      }
      > a {
        &:hover {
          .product-title {
            color: var(--color1);
          }
        }
      }
    }
  }
}
.widget_top_rated_products {
  .product_list_widget {
    > li {
      .product-title {
        a {
          color: #212121 !important;
        }
        &:hover {
          a {
            color: var(--color1) !important;
          }
        }
      }
      > a {
        &:hover {
          .product-title {
            color: var(--color1);
          }
        }
      }
    }
  }
}
.widgettitle {
  font-size: 20px;
  line-height: 28px;
  &:before {
    background-color: var(--color1);
  }
}
.shop_table {
  .product-name {
    > a {
      &:not(.yith-wcqv-button) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .product-remove {
    a {
      &:hover {
        background-color: var(--color1);
      }
    }
  }
}
.woocommerce {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .wishlist_table {
    td.product-add-to-cart {
      a {
        color: #000000;
        font-family: Montserrat;
        font-weight: 700;
        letter-spacing: 0px;
        background-color: var(--button-color1);
        &:hover {
          color: #ffffff !important;
          background-color: #212121;
        }
      }
    }
  }
}
#loop-posts {
  .post-title {
    &:hover {
      color: var(--color1);
    }
    a {
      &:hover {
        color: var(--color1);
      }
    }
    min-height: 0px;
  }
}
.et-shortcode-posts {
  .post-title {
    &:hover {
      color: var(--color1);
    }
    a {
      &:hover {
        color: var(--color1);
      }
    }
    min-height: 0px;
  }
}
.loop-product {
  .post-title {
    &:hover {
      color: var(--color1);
    }
    a {
      &:hover {
        color: var(--color1);
      }
    }
    min-height: 43px;
    max-height: 43px;
    overflow: hidden;
  }
  .wooscp-btn {
    &:hover {
      color: var(--color1) !important;
    }
  }
  .wooscp-btn.wooscp-btn-added {
    color: var(--color1) !important;
  }
}
.post-social-share {
  > .social-links {
    > a {
      &:hover {
        color: var(--color1);
      }
    }
  }
}
.related-posts {
  .post {
    .post-title {
      a {
        &:hover {
          color: var(--color1);
        }
      }
    }
  }
}
.search-posts {
  .post-title {
    a {
      &:hover {
        color: var(--color1);
      }
    }
    &:hover {
      a {
        color: var(--color1);
      }
    }
  }
}
.et-testimonial-item {
  .rating {
    span {
      color: var(--color1);
    }
  }
}
.plyr--full-ui {
  input[type='range'] {
    color: var(--color1);
  }
}
.comment-meta {
  .comment-date-time {
    a {
      &:hover {
        color: var(--color1);
      }
    }
  }
}
.comment-author {
  a {
    &:hover {
      color: var(--color1);
    }
  }
}
.comment-content {
  .edit-link {
    a {
      a {
        color: var(--color1);
        &:hover {
          color: var(--color1);
        }
      }
    }
  }
}
.woocommerce-review-link {
  color: var(--color1);
  &:hover {
    color: var(--color1);
  }
}
.widget {
  .woocommerce-Price-amount {
    color: var(--color1);
  }
}
.star-rating {
  color: var(--color1);
}
.comment-form-rating {
  a {
    color: var(--color1);
    &:after {
      color: var(--color1);
    }
  }
}
.dgwt-wcas-sp {
  .woocommerce-Price-amount {
    color: var(--color1);
  }
}
.dgwt-wcas-pd-price {
  .woocommerce-Price-amount {
    color: var(--color1);
  }
}
.post-meta {
  > * {
    color: var(--color1);
  }
  a {
    &:hover {
      color: var(--color1) !important;
    }
  }
}
.enovathemes-filter {
  .filter.active {
    color: var(--color1);
  }
  .filter-toggle {
    background-color: var(--color1);
  }
}
.shop-cat-list {
  a {
    &:hover {
      color: var(--color1);
    }
  }
  a.active {
    color: var(--color1);
  }
}
#yith-quick-view-close {
  &:hover {
    color: var(--color1) !important;
  }
}
.product-layout-single {
  .summary {
    .yith-wcwl-add-to-wishlist {
      a {
        &:hover {
          color: var(--color1) !important;
        }
      }
    }
  }
}
.yith-wcwl-add-to-wishlist {
  a {
    &:hover {
      color: var(--color1) !important;
    }
    &:after {
      border-left-color: var(--color1);
    }
  }
  a.added {
    color: var(--color1) !important;
  }
  .hide {
    + {
      .show {
        a {
          color: var(--color1) !important;
        }
      }
    }
  }
}
.custom-product {
  .wooscp-btn {
    &:hover {
      color: var(--color1) !important;
    }
  }
  .wooscp-btn.wooscp-btn-added {
    color: var(--color1) !important;
  }
}
.widget_rating_filter {
  ul {
    li {
      a {
        &:hover {
          &:after {
            color: var(--color1) !important;
          }
          &:before {
            box-shadow: inset 0 0 0 2px var(--color1);
          }
        }
      }
    }
    li.chosen {
      > a {
        &:after {
          color: var(--color1) !important;
        }
        &:before {
          box-shadow: inset 0 0 0 2px var(--color1);
        }
      }
    }
  }
}
.dgwt-wcas-pd-rating {
  .star-rating {
    color: var(--color1) !important;
  }
}
.post {
  .post-meta {
    color: var(--color1) !important;
  }
}
.related-posts-title {
  color: var(--color1) !important;
}
.comment-reply-title {
  color: var(--color1) !important;
}
.comments-title {
  color: var(--color1) !important;
}
.upsells {
  > h4 {
    color: var(--color1) !important;
  }
}
.crosssells {
  > h4 {
    color: var(--color1) !important;
  }
}
.related {
  > h4 {
    color: var(--color1) !important;
  }
}
.et-person {
  .name {
    color: var(--color1) !important;
  }
}
.post-read-more {
  &:after {
    background-color: var(--color1);
  }
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.comment-reply-link {
  &:after {
    background-color: var(--color1);
  }
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.enovathemes-navigation {
  a {
    &:hover {
      background-color: var(--color1);
      box-shadow: inset 0 0 0 1px var(--color1);
    }
  }
  .current {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
.post-sticky {
  background-color: var(--color1);
}
.post-media {
  .flex-direction-nav {
    li {
      a {
        &:hover {
          background-color: var(--color1);
        }
      }
    }
  }
  .flex-control-nav {
    li {
      a {
        &:hover {
          background-color: var(--color1);
        }
      }
      a.flex-active {
        background-color: var(--color1);
      }
    }
  }
}
.slick-dots {
  li {
    button {
      &:hover {
        background-color: var(--color1);
      }
    }
  }
  li.slick-active {
    button {
      background-color: var(--color1);
    }
  }
}
.owl-carousel {
  .owl-nav {
    > * {
      &:hover {
        background-color: var(--color1);
      }
    }
  }
  .owl-dots {
    > .owl-dot.active {
      background-color: var(--color1);
    }
  }
}
.overlay-flip-hor {
  .overlay-hover {
    .post-image-overlay {
      background-color: var(--color1);
    }
  }
}
.overlay-flip-ver {
  .overlay-hover {
    .post-image-overlay {
      background-color: var(--color1);
    }
  }
}
.image-move-up {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.image-move-down {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.image-move-left {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.image-move-right {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.overlay-image-move-up {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.overlay-image-move-down {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.overlay-image-move-left {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.overlay-image-move-right {
  .post-image-overlay {
    background-color: var(--color1);
  }
}
.product-quick-view {
  &:hover {
    background-color: var(--color1);
  }
}
.added_to_cart {
  background-color: var(--color1);
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  &:after {
    background-color: var(--color1);
  }
}
.woocommerce-store-notice.demo_store {
  background-color: var(--color1);
}
.et-accordion {
  .toggle-title.active {
    background-color: var(--color1);
  }
}
.tabset {
  .tab.active {
    background-color: var(--color1);
  }
}
.et-mailchimp {
  input[type='text'] {
    + {
      .after {
        background-color: var(--color1);
      }
    }
  }
}
.mob-menu-toggle-alt {
  background-color: var(--color1);
}
.full {
  #loop-posts {
    .format-link {
      .post-body-inner-wrap {
        background-color: var(--color1);
      }
    }
  }
}
.single-post-page {
  > .format-link {
    .format-container {
      background-color: var(--color1);
    }
  }
}
.plyr--audio {
  .plyr__control.plyr__tab-focus {
    background-color: var(--color1);
  }
  .plyr__control {
    &:hover {
      background-color: var(--color1);
    }
  }
  .plyr__control[aria-expanded='true'] {
    background-color: var(--color1);
  }
}
.plyr--video {
  .plyr__control.plyr__tab-focus {
    background-color: var(--color1);
  }
  .plyr__control {
    &:hover {
      background-color: var(--color1);
    }
  }
  .plyr__control[aria-expanded='true'] {
    background-color: var(--color1);
  }
  .plyr__controls {
    background: rgba(224, 92, 11, 0.5) !important;
  }
}
.plyr__control--overlaid {
  &:focus {
    background-color: var(--color1);
  }
  &:hover {
    background-color: var(--color1);
  }
  background-color: var(--color1);
}
.plyr__menu__container {
  .plyr__control[role='menuitemradio'][aria-checked='true'] {
    &::before {
      background-color: var(--color1);
    }
  }
}
.et-image {
  .curtain {
    background-color: var(--color1);
  }
}
.et-breadcrumbs {
  a {
    &:after {
      background-color: var(--color1);
    }
  }
}
.nivo-lightbox-prev {
  &:hover {
    background-color: var(--color1);
  }
}
.nivo-lightbox-next {
  &:hover {
    background-color: var(--color1);
  }
}
.nivo-lightbox-close {
  &:hover {
    background-color: var(--color1);
  }
}
.et-video {
  .modal-video-poster {
    &:before {
      background-color: var(--color1);
    }
  }
}
.widget_title {
  &:before {
    background-color: var(--color1);
  }
}
.widget-area {
  .dgwt-wcas-sf-wrapp {
    button.dgwt-wcas-search-submit {
      background-color: var(--color1);
      &:hover {
        background-color: rgb(204, 72, -9) !important;
      }
    }
    input[type='search'].dgwt-wcas-search-input {
      border-color: var(--color1) !important;
    }
  }
}
.post-date-side {
  background-color: var(--color1);
}
.single-product-progress {
  .bar {
    background-color: var(--color1);
  }
}
.shop-cat-list-title {
  span {
    &:before {
      background-color: var(--color1);
    }
    &:after {
      background-color: var(--color1);
    }
  }
}
.mejs-controls {
  .mejs-time-rail {
    .mejs-time-current {
      background-color: var(--color1) !important;
    }
  }
}
.slick-slider {
  .slick-prev {
    &:hover {
      background-color: var(--color1) !important;
    }
  }
  .slick-next {
    &:hover {
      background-color: var(--color1) !important;
    }
  }
}
.widget_tag_cloud {
  .tagcloud {
    a {
      &:after {
        background-color: var(--color1) !important;
      }
      &:hover {
        &:after {
          background-color: var(--color1) !important;
        }
      }
      font-family: Montserrat;
      font-weight: 700;
      letter-spacing: 0px;
    }
  }
}
.widget_product_tag_cloud {
  .tagcloud {
    a {
      &:after {
        background-color: var(--color1) !important;
      }
      &:hover {
        &:after {
          background-color: var(--color1) !important;
        }
      }
      font-family: Montserrat;
      font-weight: 700;
      letter-spacing: 0px;
    }
  }
}
#cboxClose {
  &:hover {
    background-color: var(--color1) !important;
  }
}
.wooscp-area {
  .wooscp-inner {
    .wooscp-bar {
      .wooscp-bar-btn {
        background-color: var(--color1) !important;
      }
    }
  }
}
ul.chat {
  li {
    &:nth-child(2n + 2) > p {
      background-color: rgba(224, 92, 11, 0.1);
      color: var(--color1) !important;
    }
  }
}
.plyr__control.plyr__tab-focus {
  box-shadow: 0 8px 24px 0 rgba(224, 92, 11, 0.5);
}
#yith-wcwl-popup-message {
  color: var(--color1) !important;
  box-shadow: inset 0 0 0 1px var(--color1);
}
.post-image-overlay {
  > .overlay-read-more {
    &:hover {
      color: var(--color1) !important;
      box-shadow: inset 0 0 0 1px var(--color1);
    }
  }
  > .post-image-overlay-content {
    > .overlay-read-more {
      &:hover {
        color: var(--color1) !important;
        box-shadow: inset 0 0 0 1px var(--color1);
      }
    }
  }
}
.ajax-add-to-cart-loading {
  .circle-loader {
    border-left-color: var(--color1);
  }
  .load-complete {
    border-color: var(--color1) !important;
  }
  .checkmark {
    &:after {
      border-right: 1px solid var(--color1);
      border-top: 1px solid var(--color1);
    }
  }
}
blockquote {
  background-color: rgba(224, 92, 11, 0.05) !important;
  border-left: 8px solid var(--color1) !important;
  color: var(--color1) !important;
}
.wp-block-quote[style*='text-align:right'] {
  border-right: 8px solid var(--color1) !important;
  border-right: 8px solid var(--color1) !important;
}
.et-pricing-table.highlight-true {
  .pricing-table-inner {
    border-color: var(--color1) !important;
  }
}
.counter-moving-child {
  &:before {
    border-color: var(--color1);
  }
}
.highlight-true {
  .testimonial-content {
    box-shadow: inset 0 0 0 1px var(--color1);
    border-color: var(--color1);
    &:after {
      border-color: var(--color1) transparent transparent transparent;
    }
  }
}
.woocommerce-product-gallery {
  .flex-control-nav {
    li {
      img.flex-active {
        box-shadow: 0 0 0 1px var(--color1);
      }
    }
  }
}
.overlay-fall {
  .overlay-hover {
    .post-image-overlay {
      background-color: var(--color1);
    }
  }
}
#header-menu-default {
  > .menu-item.depth-0 {
    > .mi-link {
      .txt {
        &:after {
          border-bottom-color: var(--color1);
        }
      }
    }
  }
}
a {
  &:hover {
    color: var(--color1);
  }
}
.product_meta {
  a {
    &:hover {
      color: var(--color1);
    }
  }
}
#to-top {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  font-size: 16px;
  border-radius: 4px;
  color: #ffffff;
  background-color: var(--color1);
  &:hover {
    color: #000000;
    background-color: #ffffff;
  }
  .et-ink {
    background-color: #000000;
  }
}
html {
  background-color: #ffffff;
}
#gen-wrap {
  background-color: #ffffff;
}
.site-loading {
  background-color: #ffffff;
  .site-loading-bar {
    &:after {
      background-color: var(--color1);
    }
  }
}
.et-testimonial {
  .author-info-wrapper {
    .rating {
      span {
        color: var(--color1);
      }
    }
  }
}
.single-product-wrapper {
  .onsale {
    background-color: var(--button-color1);
    color: #000000;
  }
}
// input[type='date'],
// input[type='datetime'],
// input[type='datetime-local'],
// input[type='email'],
// input[type='month'],
// input[type='number'],
// input[type='password'],
// input[type='search'],
// input[type='tel'],
// input[type='text'],
// input[type='time'],
// input[type='url'],
// input[type='week'],
// input[type='file'] {
//   color: var(--color2);
//   background-color: #ffffff;
//   border-color: var(--color3);
//   &:focus {
//     color: var(--text-color-hover2);
//     border-color: var(--color-hover3);
//     background-color: #ffffff;
//   }
// }
.tech-page-search-form {
  .search-icon {
    color: var(--text-color1) !important;
  }
  [type='submit']#searchsubmit {
    &:hover {
      + {
        .search-icon {
          color: var(--text-color1) !important;
        }
      }
    }
  }
}
.widget_search {
  form {
    input[type='submit']#searchsubmit {
      + {
        .search-icon {
          color: var(--text-color1) !important;
        }
      }
      &:hover {
        + {
          .search-icon {
            color: var(--text-color1) !important;
          }
        }
      }
    }
  }
}
.select2-container--default {
  .select2-selection--single {
    color: var(--text-color1) !important;
    background-color: #ffffff !important;
    border-color: #e0e0e0 !important;
    .select2-selection__rendered {
      color: var(--text-color1) !important;
      &:focus {
        color: var(--text-color1) !important;
      }
    }
    &:focus {
      color: var(--text-color1) !important;
      border-color: #ffd800 !important;
      background-color: #ffffff !important;
    }
  }
  .select2-search--dropdown {
    .select2-search__field {
      background-color: #ffffff !important;
      &:focus {
        background-color: #ffffff !important;
      }
    }
  }
}
.select2-dropdown {
  background-color: #ffffff !important;
  &:focus {
    background-color: #ffffff !important;
  }
}
input[type='button'] {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
input[type='reset'] {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
input[type='submit'] {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
button {
  // &:not(.plyr__control) {
  &.theme-button {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);

    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
a.checkout-button {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.return-to-shop {
  a {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
.wishlist_table {
  .product-add-to-cart {
    a {
      color: #000000;
      font-family: Montserrat;
      font-weight: 700;
      letter-spacing: 0px;
      background-color: var(--button-color1);
      &:hover {
        color: #ffffff !important;
        background-color: #212121;
      }
    }
  }
  .yith-wcqv-button {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
a.woocommerce-button {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
#page-links {
  > a {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
.edit-link {
  a {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
.woocommerce-message {
  .button {
    color: #000000;
    color: #000000;
    font-family: Montserrat;
    font-family: Montserrat;
    font-weight: 700;
    font-weight: 700;
    letter-spacing: 0px;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    color: #000000 !important;
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
      color: #ffffff !important;
    }
  }
}
a.error404-button {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
}
.vin-result-btn {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.pricing-table-footer {
  .et-button {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
.et-button {
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
}
.et-ajax-loader {
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.woocommerce-mini-cart__buttons {
  > a {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    &:hover {
      color: #ffffff !important;
      background-color: #212121;
    }
  }
}
.post-tags {
  a {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
  }
}
.post-tags-single {
  a {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
  }
}
.error404-button {
  &:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}
.et-ajax-loader.loading {
  &:hover {
    &:after {
      border: 2px solid #ffffff;
    }
  }
}
.vc-row-649779 {
  .fixed-container {
    background-image: url(../../images/banner_back_6-1.jpg);
  }
}
.et-gap-928357 {
  height: 8px;
}
.et-gap-880736 {
  height: 16px;
}
.et-gap-200657 {
  height: 40px;
}
.et-gap-36730 {
  height: 78px;
}
.et-gap-269677 {
  height: 28px;
}
.et-gap-498251 {
  height: 24px;
}
.et-gap-366327 {
  height: 24px;
}
.et-gap-263945 {
  height: 24px;
}
.et-gap-842058 {
  height: 150px;
}
.et-gap-768360 {
  height: 48px;
}
.et-gap-969983 {
  height: 48px;
}
.et-gap-431129 {
  height: 48px;
}
.et-gap-106300 {
  height: 2px;
}
.et-gap-100914 {
  height: 16px;
}
.et-gap-438949 {
  height: 40px;
}
.et-gap-670747 {
  height: 25px;
}
.et-gap-968368 {
  height: 48px;
}
.et-gap-63768 {
  height: 40px;
}
.et-gap-793604 {
  height: 24px;
}
.et-gap-987940 {
  height: 40px;
}
.et-gap-354476 {
  height: 32px;
}
.et-gap-491498 {
  height: 42px;
}
.et-gap-350849 {
  height: 9px;
}
.et-gap-381245 {
  height: 40px;
}
.et-gap-479483 {
  height: 24px;
}
.et-gap-559174 {
  height: 68px;
}
.et-gap-988338 {
  height: 51px;
}
.et-gap-549891 {
  height: 29px;
}
.et-gap-712976 {
  height: 48px;
}
.et-gap-277510 {
  height: 48px;
}
.et-gap-241341 {
  height: 278px;
}
.et-gap-2884 {
  height: 281px;
}
#homepage-section-2 {
  background-color: var(--cmp-hover-color1);

  .et-item-set {
    background-color: var(--cmp-hover-color1);
    min-height: 0 !important;

    .et-item {
      padding: 32px 32px 32px 32px;

      .et-icon-box-title {
        color: var(--text-color1);
      }

      .et-icon {
        color: var(--cmp-color1);
        border-radius: 0px;
      }

      &:nth-child(even) {
        background-color: var(--cmp-color1);

        .et-icon {
          color: var(--cmp-hover-color1);
          border-radius: 0px;
        }
      }
    }
  }
}
// #et-icon-box-container-949741 {
//   min-height: 0 !important;
// }
// #et-heading-733352 {
//   .text-wrapper {
//     background-color: transparent;
//     padding: 0;
//   }
//   color: var(--color1);
//   font-weight: 700;
//   line-height: 24px;
//   text-transform: none;
//   margin: 0px 0px 8px 0px;
//   a {
//     color: var(--color1);
//   }
// }
// #et-heading-629235 {
//   .text-wrapper {
//     background-color: transparent;
//     padding: 0;
//   }
//   color: var(--text-color1);
//   font-weight: 500;
//   line-height: 24px;
//   text-transform: none;
//   margin: 0px 0px 0px 0px;
//   a {
//     color: var(--text-color1);
//   }
// }
#et-heading-291849 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-674919 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-152361 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-384573 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-48667 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-192485 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-976511 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-791429 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 1px;
  a {
    color: #ffffff;
  }
}
#et-heading-691691 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-890147 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px -3px;
  a {
    color: #ffffff;
  }
}
#et-heading-571244 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 1px;
  a {
    color: #ffffff;
  }
}
#et-heading-71633 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-218620 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px -3px;
  a {
    color: #ffffff;
  }
}
#et-heading-794313 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-662852 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-228777 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-954086 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-919332 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-222208 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-903239 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 4px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-913174 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color2);
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 0px 2px;
  a {
    color: var(--color2);
  }
}
#et-heading-809889 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color5);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 0px 1px;
  a {
    color: var(--color5);
  }
}
#et-heading-565944 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 4px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-229599 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
    color: #ffffff;
  }
}
#et-heading-213222 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px 0px;
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px 0px;
  a {
    color: #ffffff;
    color: #ffffff;
  }
}
#et-heading-157487 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 0px 2px;
  a {
    color: #ffffff;
  }
}
#et-heading-343571 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-29151 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 2px -3px;
  a {
    color: #ffffff;
  }
}
#et-heading-315919 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
#et-ad-412032 {
  .background {
    background-image: url(../../images/banner_back_3-2.jpg);
    background-color: #212121;
    background-position: right center;
  }
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
}
#et-ad-420702 {
  .background {
    background-image: url(../../images/banner_back_4-2.jpg);
    background-color: #212121;
    background-position: right center;
  }
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
}
#et-ad-909984 {
  border-radius: 8px;
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
  .background {
    background-color: var(--color1);
    background-position: right bottom;
  }
  .et-ad-img {
    left: 350px;
    top: 50px;
  }
}
#et-ad-386840 {
  border-radius: 8px;
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slide_asset2-2-2.jpg);
    background-color: #f5f5f5;
    background-position: -50% top;
  }
  .et-ad-img {
    left: 300px;
    top: 50px;
  }
}
#et-ad-996057 {
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: 220px;
    top: 14px;
  }
  padding: 32px 32px 32px 32px;
  margin: 0px 0px 8px 0px;
}
#et-ad-916825 {
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: var(--button-color1);
    background-position: left top;
  }
  .et-ad-img {
    left: 180px;
    top: 32px;
  }
  padding: 32px 32px 32px 32px;
  margin: 0px 0px 0px 0px;
}
#et-ad-41747 {
  .background {
    background-image: url(../../images/slide_asset3-2.jpg);
    background-color: var(--color1);
    background-position: left bottom;
  }
  .et-ad-img {
    left: 12px;
    top: 165px;
  }
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
}
#et-ad-463409 {
  .background {
    background-image: url(../../images/banner_back_2-1.jpg);
    background-color: #212121;
    background-position: left bottom;
  }
  .et-ad-img {
    left: -50px;
    top: 135px;
  }
  padding: 48px 40px 40px 40px;
  margin: 0px 0px 0px 0px;
}
#et-image-137977 {
  -ms-transform: translate3d(32px, 180px, 0px);
  transform: translate3d(32px, 180px, 0px);
}
#et-button-494372 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #000000;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #ffffff;
  }
  .et-ink {
    color: #000000;
    background-color: #000000;
  }
}
#et-button-851630 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #000000;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #ffffff;
  }
  .et-ink {
    color: #000000;
    background-color: #000000;
  }
}
#et-button-738203 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #000000;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #ffffff;
  }
  .et-ink {
    color: #000000;
    background-color: #000000;
  }
}
#et-button-816957 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #000000;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #ffffff;
  }
  .et-ink {
    color: #000000;
    background-color: #000000;
  }
}
#et-button-468513 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #000000;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-746822 {
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #000000;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-646085 {
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #ffffff;
  }
  .hover {
    background-color: #000000;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-452001 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #000000;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-917141 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #000000;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #ffffff;
  }
  .et-ink {
    color: #000000;
    background-color: #000000;
  }
}
#et-icon-box-64254 {
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-211543 {
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-527273 {
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-641241 {
  background-color: #ffffff;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-839051 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-940577 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-283259 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-743237 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-743967 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-824418 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-998682 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-icon-box-534270 {
  background-color: #f5f5f5;
  padding: 32px 32px 32px 32px;
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
  &:hover {
    .ghost-icon {
      color: #000000;
    }
    .et-icon {
      color: #000000;
    }
  }
}
#et-make-521199 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-360367 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-746330 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-824256 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-191042 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-972870 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-507624 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-147383 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-875946 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-997713 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-982995 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-332007 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-64946 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-77748 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-842514 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
#et-make-880817 {
  background-color: #f5f5f5;
  &:hover {
    background-color: #f0f0f0;
  }
  .et-make-title {
    color: #212121;
  }
}
.et-icon-420246 {
  color: #ffffff;
  border-radius: 0px;
}
#et-mailchimp-80046 {
  .field {
    color: var(--text-color1);
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    &:focus {
      color: var(--text-color1);
      background-color: #ffffff;
      border: 1px solid #bdbdbd;
    }
  }
  &::placeholder {
    color: var(--text-color1);
  }
  .send-div {
    .button {
      color: #000000;
      background-color: var(--button-color1);
      &:hover {
        color: #ffffff;
        background-color: #212121 !important;
      }
    }
    &:hover {
      .sending {
        color: #ffffff;
        background-color: #212121 !important;
      }
    }
  }
  .sending {
    color: #000000;
    background-color: var(--button-color1);
  }
}
.header {
  .vc-row-504195 {
    height: 64px;
    .hbe {
      line-height: 63px;
    }
  }
  .vc-row-770266 {
    height: 56px;
    .hbe {
      line-height: 55px;
    }
  }
  .vc-row-466261 {
    height: 48px;
    .hbe {
      line-height: 47px;
    }
  }
  .vc-row-271378 {
    z-index: 2;
    height: 96px;
    .hbe {
      line-height: 95px;
    }
  }
  .vc-row-617872 {
    z-index: 1;
    height: 56px;
    .hbe {
      line-height: 55px;
    }
  }
}
.header.sticky-true.active {
  .vc-row-770266 {
    height: 56px;
    .hbe {
      line-height: 55px;
      height: 56px;
    }
  }
}
#header-woo-search-form-885237 {
  .dgwt-wcas-search-submit {
    color: #ffffff;
    background-color: #000000;
    box-shadow: none;
    &:hover {
      color: #ffffff;
      background-color: #000000;
      box-shadow: none;
    }
  }
  width: 960px;
  margin: -1px 0px 0px 0px;
  .dgwt-wcas-search-wrapp {
    height: 48px;
  }
  input.dgwt-wcas-search-input {
    width: 960px;
    color: var(--text-color1);
    background-color: #ffffff;
    border-color: #ffffff;
  }
}
#header-icon-40835 {
  .hicon {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }
  &:hover {
    .hicon {
      color: #eeeeee;
      box-shadow: none;
    }
  }
}
#header-icon-91751 {
  .hicon {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }
  &:hover {
    .hicon {
      color: #eeeeee;
      box-shadow: none;
    }
  }
}
.header-vertical-separator-722008 {
  .line {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-bottom-style: solid;
    height: 32px;
    border-bottom-width: 32px;
    width: 1px;
  }
  margin: 0px 8px 0px 8px;
}
// #mobile-toggle-954982 {
//   color: #ffffff;
//   background-color: transparent;
//   box-shadow: none;
//   &:hover {
//     color: #eeeeee;
//     background-color: transparent;
//     box-shadow: none;
//   }
// }
// #mobile-container-toggle-954982 {
//   margin: 0px 4px 0px 0px;
// }
#header-logo-115569 {
  .logo {
    max-height: 20px;
  }
  .sticky-logo {
    max-height: 20px;
  }
  margin: 0px 0px 0px 8px;
}
#header-logo-880392 {
  .logo {
    max-height: 26px;
  }
  margin: 16px 0px 0px 12px;
}
// #mobile-container-814131 {
//   color: var(--text-color1);
//   background-color: #ffffff;
//   padding: 16px 0px 32px 0px;
// }
#mobile-close-856662 {
  color: #bdbdbd;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    color: #212121;
    box-shadow: none;
  }
}
#mobile-container-close-856662 {
  margin: 12px 8px 0px 0px;
}
.et-gap-549073 {
  height: 16px;
}
.et-gap-654213 {
  height: 8px;
}
#et-heading-501901 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 0px 16px;
  a {
    color: var(--color1);
  }
}
#et-heading-567116 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 0px 16px;
  a {
    color: var(--color1);
  }
}
#et-heading-800854 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 0px 16px;
  a {
    color: var(--color1);
  }
}
// #mobile-menu-324351 {
//   > .menu-item {
//     > a {
//       color: #212121;
//       font-size: 16px;
//       line-height: 26px;
//       font-weight: 600;
//       letter-spacing: 0px;
//       text-transform: none;
//       &:hover {
//         background-color: transparent;
//         color: var(--color1);
//       }
//     }
//     &:last-child {
//       &:after {
//         background-color: #e0e0e0;
//       }
//     }
//     .sub-menu {
//       .menu-item {
//         > a {
//           color: var(--text-color1);
//           font-size: 16px;
//           line-height: 26px;
//           font-weight: 600;
//           text-transform: none;
//           &:hover {
//             color: var(--color1);
//             background-color: transparent;
//           }
//         }
//       }
//     }
//   }
//   .menu-item {
//     > a {
//       &:after {
//         background-color: #e0e0e0;
//       }
//     }
//   }
// }
#mobile-menu-container-324351 {
  margin: 0px 0px 0px 0px;
}
#mobile-menu-456260 {
  > .menu-item {
    > a {
      color: #212121;
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: none;
      &:hover {
        background-color: transparent;
        color: var(--color1);
      }
    }
    &:last-child {
      &:after {
        background-color: #e0e0e0;
      }
    }
    .sub-menu {
      .menu-item {
        > a {
          color: var(--text-color1);
          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
          text-transform: none;
          &:hover {
            color: var(--color1);
            background-color: transparent;
          }
        }
      }
    }
  }
  .menu-item {
    > a {
      &:after {
        background-color: #e0e0e0;
      }
    }
  }
}
#mobile-menu-container-456260 {
  margin: 0px 0px 0px 0px;
}
#header-slogan-317196 {
  margin: 0px 0px 0px 0px;
}
#header-slogan-79048 {
  margin: 0px 0px 0px 0px;
}
#et-header-button-841587 {
  > a {
    width: 296px;
    height: 48px;
    line-height: 47px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px !important;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #000000;
    border-radius: 4px;
    .icon {
      font-size: 18px !important;
      margin-right: 8px !important;
    }
    .regular {
      background-color: var(--button-color1);
    }
    .hover {
      background-color: #000000;
    }
    .et-ink {
      color: #000000;
      background-color: #ffffff;
    }
  }
  &:hover {
    > a {
      color: #ffffff;
    }
  }
  margin: 0px 0px 0px 12px;
}
#et-header-button-23058 {
  > a {
    width: 296px;
    height: 48px;
    line-height: 47px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px !important;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #000000;
    border-radius: 4px;
    .icon {
      font-size: 18px !important;
      margin-right: 8px !important;
    }
    .regular {
      background-color: var(--button-color1);
    }
    .hover {
      background-color: #000000;
    }
    .et-ink {
      color: #000000;
      background-color: #ffffff;
    }
  }
  &:hover {
    > a {
      color: #ffffff;
    }
  }
  margin: 0px 0px 0px 12px;
}
.vc_custom_1559210763022 {
  border-bottom-width: 1px !important;
  background-color: var(--color1) !important;
  border-bottom-color: #bc5709 !important;
  border-bottom-style: solid !important;
}
.vc_custom_1559210719611 {
  background-color: var(--color1) !important;
}
#header-icon-543050 {
  .hicon {
    color: var(--color1);
    background-color: transparent;
    box-shadow: none;
    margin: -2px 0px 0px 0px;
  }
  &:hover {
    .hicon {
      color: var(--color1);
      box-shadow: none;
    }
  }
}
#header-icon-26895 {
  .hicon {
    color: var(--color1);
    background-color: transparent;
    box-shadow: none;
    margin: -2px 0px 0px 24px;
  }
  &:hover {
    .hicon {
      color: var(--color1);
      box-shadow: none;
    }
  }
}
#header-icon-802563 {
  .hicon {
    color: var(--color1);
    background-color: transparent;
    box-shadow: none;
    margin: -2px 0px 0px 24px;
  }
  &:hover {
    .hicon {
      color: var(--color1);
      box-shadow: none;
    }
  }
}
#header-icon-788327 {
  .hicon {
    color: #bdbdbd;
    background-color: transparent;
    font-size: 32px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }
  &:hover {
    .hicon {
      color: #212121;
      box-shadow: none;
    }
  }
}
#header-icon-787254 {
  .hicon {
    color: #bdbdbd;
    background-color: transparent;
    font-size: 32px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }
  &:hover {
    .hicon {
      color: #212121;
      box-shadow: none;
    }
  }
}
#header-slogan-904693 {
  margin: 0px 0px 0px 0px;
}
#header-slogan-887567 {
  margin: 0px 0px 0px 0px;
}
#header-slogan-172029 {
  margin: 0px 0px 0px 0px;
}
#login-toggle-936942 {
  color: #bdbdbd;
  background-color: transparent;
  box-shadow: none;
  &:hover {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
  }
}
#header-login-936942 {
  .widget_reglog {
    color: var(--text-color1);
    background-color: #ffffff;
    a {
      color: var(--color1);
    }
    .input {
      color: var(--text-color1);
      background-color: #ffffff;
      box-shadow: inset 0 0 0 1px #e0e0e0;
    }
    // .button {
    //   color: #000000;
    //   background-color: var(--button-color1);
    //   box-shadow: none;
    //   &:hover {
    //     color: #ffffff;
    //     background-color: #212121;
    //     box-shadow: none;
    //   }
    // }
  }
  margin: 0px 0px 0px 0px;
}
.header-vertical-separator-144873 {
  .line {
    border-bottom-color: #424242;
    border-bottom-style: solid;
    height: 24px;
    border-bottom-width: 24px;
    width: 1px;
  }
  margin: 0px 8px 0px 8px;
}
.header-vertical-separator-441595 {
  .line {
    border-bottom-color: #424242;
    border-bottom-style: solid;
    height: 24px;
    border-bottom-width: 24px;
    width: 1px;
  }
  margin: 0px 8px 0px 16px;
}
.header-vertical-separator-855577 {
  .line {
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
    height: 32px;
    border-bottom-width: 32px;
    width: 1px;
  }
  margin: 0px 16px 0px 20px;
}
#language-switcher-421048 {
  .language-toggle {
    color: #bdbdbd;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      color: #ffffff;
      background-color: transparent;
      box-shadow: none;
    }
  }
  ul {
    background-color: #ffffff;
    width: 140px;
    li {
      a {
        color: var(--text-color1);
        background-color: #ffffff;
        &:hover {
          color: var(--color1);
          background-color: #f5f5f5;
        }
      }
    }
  }
  .box-align-center {
    ul {
      margin-left: -70px;
    }
  }
  margin: 0px 0px 0px 0px;
}
#currency-switcher-365957 {
  .currency-toggle {
    color: #bdbdbd;
    &:hover {
      color: #ffffff;
    }
  }
  .highlighted-currency {
    color: var(--color1);
  }
  .currency-list {
    background-color: #ffffff;
    width: 80px;
    a {
      color: var(--text-color1);
      background-color: #ffffff;
      &:hover {
        color: var(--color1);
        background-color: #f5f5f5;
      }
    }
  }
  margin: 0px 0px 0px 0px;
}
#currency-switcher-365957.box-align-center {
  .currency-list {
    margin-left: -40px;
  }
}
#header-logo-617774 {
  .logo {
    max-height: 52px;
  }
  .sticky-logo {
    max-height: 52px;
  }
  margin: 0px 36px 0px 0px;
}
#cart-toggle-365707 {
  color: #bdbdbd;
  &:hover {
    color: #212121;
  }
  .cart-contents {
    color: var(--text-color1);
    .cart-title {
      color: var(--color1);
    }
  }
}
// #cart-box-365707 {
//   color: var(--text-color1);
//   background-color: #ffffff;
//   .product_list_widget {
//     li {
//       &:not(:last-child) {
//         &:before {
//           background-color: #212121;
//         }
//       }
//     }
//   }
//   .woocommerce-mini-cart__total {
//     &:before {
//       background-color: #212121;
//     }
//   }
//   .cart-product-title {
//     color: #212121;
//   }
//   .cart_list {
//     li {
//       .remove {
//         color: var(--text-color1);
//       }
//     }
//   }
//   .woocommerce-mini-cart__buttons {
//     > a {
//       color: #000000;
//       background-color: var(--button-color1);
//       &:hover {
//         color: #ffffff !important;
//         background-color: #212121;
//       }
//     }
//   }
// }
// #header-cart-365707 {
//   margin: -1px 0px 0px 0px;
// }
#header-menu-846790 {
  > .menu-item.depth-0 {
    margin-left: 40px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    &:before {
      background: #e0e0e0;
      left: -20px;
    }
    &:after {
      background: #e0e0e0;
      right: -20px;
    }
    > .mi-link {
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: none;
      .txt {
        &:after {
          border-bottom-color: var(--color1);
        }
      }
    }
    &:hover {
      > .mi-link {
        color: var(--color1);
      }
    }
  }
  > .menu-item.depth-0.current-menu-item {
    > .mi-link {
      color: var(--color1);
    }
  }
  > .menu-item.depth-0.current-menu-parent {
    > .mi-link {
      color: var(--color1);
    }
  }
  > .menu-item.depth-0.current-menu-ancestor {
    > .mi-link {
      color: var(--color1);
    }
  }
  > .menu-item.depth-0.one-page-active {
    > .mi-link {
      color: var(--color1);
    }
  }
  .sub-menu {
    background-color: #ffffff;
  }
  > .menu-item {
    &:not(.mm-true) {
      .sub-menu {
        .menu-item {
          .mi-link {
            &:before {
              background-color: var(--text-color1);
            }
            color: var(--text-color1);
            font-weight: 500;
            text-transform: none;
          }
          &:hover {
            > .mi-link {
              color: var(--color1);
            }
          }
        }
      }
    }
  }
}
#header-menu-container-846790 {
  margin: 0px 56px 0px 0px;
}
// #et-header-button-749434 {
//   > a {
//     width: 220px;
//     height: 48px;
//     line-height: 47px;
//     padding-top: 0;
//     padding-bottom: 0;
//     font-size: 16px !important;
//     font-weight: 700;
//     letter-spacing: 0px;
//     line-height: 48px !important;
//     text-transform: none;
//     color: #000000;
//     border-radius: 4px;
//   }
//   .icon {
//     font-size: 24px !important;
//     margin-right: 12px !important;
//   }
//   &:hover {
//     > a {
//       color: #ffffff;
//     }
//   }
//   .regular {
//     background-color: var(--button-color1);
//   }
//   .hover {
//     background-color: #000000;
//   }
//   .et-ink {
//     color: #000000;
//     background-color: #ffffff;
//   }
//   margin: 0px 0px 0px 40px;
// }
#et-header-button-141607 {
  > a {
    width: 282px;
    height: 48px;
    line-height: 47px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 48px !important;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 4px;
  }
  .icon {
    font-size: 12px !important;
  }
  &:hover {
    > a {
      color: #ffffff;
    }
  }
  .regular {
    background-color: var(--color1);
  }
  .hover {
    background-color: #212121;
  }
  .et-ink {
    color: #ffffff;
    background-color: #ffffff;
  }
  margin: 0px 0px 0px 0px;
}
#header-woo-search-form-772140 {
  .dgwt-wcas-search-submit {
    color: #ffffff;
    background-color: var(--color1);
    box-shadow: none;
    &:hover {
      color: #ffffff;
      background-color: #ce550a;
      box-shadow: none;
    }
  }
  width: 620px;
  margin: -1px 0px 0px 8px;
  .dgwt-wcas-search-wrapp {
    height: 48px;
  }
  input.dgwt-wcas-search-input {
    width: 620px;
    color: var(--text-color1);
    background-color: #ffffff;
    border-color: var(--color1);
  }
}
.vc_custom_1558529468053 {
  background-color: #212121 !important;
}
.vc_custom_1553775461826 {
  background-color: var(--color3) !important;
}
.vc_custom_1558529770673 {
  background-color: #f5f5f5 !important;
}
#et-heading-126872 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px -34px 12px;
  color: var(--color1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px -34px 12px;
  a {
    color: var(--color1);
    color: var(--color1);
  }
}
.mm-378295 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-378295 {
  margin: 4px 8px 0px 0px;
  margin: 4px 8px 0px 0px;
}
.vc_custom_1558707935156 {
  padding-top: 32px !important;
  padding-bottom: 8px !important;
}
#megamenu-2080 {
  width: 900px;
  max-width: 900px;
}
#et-button-349295 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-323138 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-624117 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-575408 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-155136 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-980440 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
.vc_custom_1558707830265 {
  padding-top: 16px !important;
}
.vc_custom_1558707807512 {
  padding-bottom: 32px !important;
}
#megamenu-2079 {
  width: 900px;
  max-width: 900px;
}
.vc_custom_1558707459286 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
#megamenu-2078 {
  width: 900px;
  max-width: 900px;
}
.et-separator-895382 {
  .line {
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
  }
}
#et-heading-950312 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #212121;
  }
}
#et-heading-271539 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #212121;
  }
}
#et-heading-411869 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #212121;
  }
}
#et-icon-list-77177 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-icon-list-997313 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-icon-list-290168 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-button-825721 {
  min-width: 216px;
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-20399 {
  min-width: 216px;
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-356251 {
  min-width: 216px;
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
.vc_custom_1558707359424 {
  padding-top: 8px !important;
  padding-bottom: 32px !important;
}
.vc_custom_1558707285655 {
  margin-top: 24px !important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  background-color: #ffffff !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
  border-radius: 1px !important;
}
.vc_custom_1558707295126 {
  margin-top: 24px !important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  background-color: #ffffff !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}
.vc_custom_1558707306191 {
  margin-top: 24px !important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  background-color: #ffffff !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}
#megamenu-2077 {
  width: 900px;
  max-width: 900px;
}
#et-icon-box-container-155466 {
  background-color: #ffffff;
  min-height: 0 !important;
}
#et-icon-box-367200 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-367200.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-260113 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-260113.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-614349 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-614349.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-721856 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-721856.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-654877 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-654877.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-187339 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-187339.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-496015 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-496015.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-949370 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-949370.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
.vc_custom_1558706995465 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
#megamenu-2076 {
  width: 900px;
  max-width: 900px;
}
.mm-448987 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-448987 {
  margin: 32px 8px 0px 8px;
  margin: 32px 8px 0px 8px;
}
#megamenu-2075 {
  width: 900px;
  max-width: 900px;
}
.mm-870065 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-870065 {
  margin: 0px 8px 0px 8px;
}
#et-heading-91551 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  text-transform: none;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--text-color1);
    color: var(--text-color1);
  }
}
#et-heading-386800 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: var(--color1);
    color: var(--color1);
  }
}
#et-heading-40884 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 8px 0px;
  a {
    color: #000000;
    color: #000000;
  }
}
#et-button-960448 {
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: #ffc600;
    background-color: #ffc600;
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
.vc_custom_1558706296753 {
  padding-top: 32px !important;
  background-image: url(../../images/mm2-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
.vc_custom_1558706365008 {
  margin-top: 20px !important;
  margin-bottom: 80px !important;
  padding-top: 0px !important;
  padding-left: 240px !important;
}
#megamenu-2074 {
  width: 900px;
  max-width: 900px;
}
.mm-786182 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-786182 {
  margin: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.vc_custom_1558705966192 {
  padding-top: 32px !important;
  background-image: url(../../images/megamenu2-4-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
#megamenu-2073 {
  width: 900px;
  max-width: 900px;
}
.mm-519050 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-519050 {
  margin: 0px 8px 0px 8px;
}
.vc_custom_1558705364384 {
  padding-top: 24px !important;
}
#megamenu-2072 {
  width: 900px;
  max-width: 900px;
}
.mm-836917 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-836917 {
  margin: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.vc_custom_1558703955955 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}
#megamenu-2063 {
  width: 900px;
  max-width: 900px;
}
.mm-23505 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-23505 {
  margin: 32px 8px 0px 8px;
}
#megamenu-880 {
  width: 1200px;
  max-width: 1200px;
}
// .vc_custom_1556196258476 {
//   padding-top: 32px !important;
//   padding-right: 24px !important;
//   padding-bottom: 32px !important;
//   padding-left: 24px !important;
//   background-color: #ffffff !important;
// }
// #megamenu-733 {
//   width: 360px;
//   max-width: 360px;
// }
// #megamenu-tab-726989 {
//   .tabset {
//     background-color: #f5f5f5;
//     .tab-item.active {
//       .arrow {
//         border-color: transparent #ffffff transparent transparent;
//       }
//     }
//   }
//   .tabs-container {
//     background-color: #ffffff;
//   }
//   .tab-item {
//     color: #212121;
//     font-size: 16px;
//     font-weight: 600;
//     letter-spacing: 0px;
//     text-transform: none;
//     &:hover {
//       color: var(--color1);
//     }
//     &:after {
//       background-color: #ffffff;
//       box-shadow: none;
//     }
//   }
//   .tab-item.active {
//     color: var(--color1);
//   }
//   .tab-content {
//     padding: 0px 0px 0px 0px;
//   }
// }
.mm-258711 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-258711 {
  margin: 0px 8px 0px 8px;
}
.mm-968976 {
  > .menu-item.depth-0 {
    > .mi-link {
      color: #212121;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      &:hover {
        color: var(--color1);
      }
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
  .sub-menu {
    .menu-item {
      .mi-link {
        &:before {
          background-color: var(--text-color1);
        }
        color: var(--text-color1);
        font-weight: 500;
        letter-spacing: 0px;
        text-transform: none;
      }
      &:hover {
        > .mi-link {
          color: var(--color1);
        }
      }
    }
  }
  > .menu-item {
    > .mi-link {
      &:before {
        background-color: #e0e0e0;
      }
    }
  }
}
#mm-container-968976 {
  margin: 0px 8px 0px 8px;
}
#et-ad-666542 {
  .background {
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -50px;
    top: 200px;
  }
  padding: 32px 24px 32px 24px;
  margin: 12px 0px 0px 0px;
}
.et-gap-696534 {
  height: 8px;
}
.et-gap-268141 {
  height: 16px;
}
.et-gap-378829 {
  height: 24px;
}
.et-gap-862840 {
  height: 8px;
}
.et-gap-284397 {
  height: 300px;
}
#et-heading-39247 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px 0px 16px 0px;
  a {
    color: #212121;
  }
}
#et-heading-3606 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px 0px 16px 0px;
  a {
    color: #212121;
  }
}
#et-heading-559687 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px 0px 16px 0px;
  a {
    color: #212121;
  }
}
#et-heading-189864 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-213149 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
#et-heading-619870 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 0px -2px;
  a {
    color: #000000;
  }
}
#et-button-43150 {
  min-width: 225px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #ffc600;
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-688425 {
  min-width: 225px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #ffc600;
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-673180 {
  min-width: 225px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #ffc600;
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-218130 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-794050 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: #f5f5f5;
  }
  .hover {
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-button-467660 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #212121;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-icon-box-container-936469 {
  background-color: #ffffff;
  min-height: 0 !important;
}
.et-separator-352792 {
  .line {
    margin-top: 0px;
    margin-bottom: 24px;
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
  }
}
#et-icon-list-215980 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-icon-list-815659 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-icon-list-488542 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
  }
}
#et-icon-box-152075 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-152075.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-732083 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-732083.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-368634 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-368634.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-769671 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-769671.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-940426 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-940426.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-98222 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-98222.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-34402 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-34402.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
#et-icon-box-363400 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;
  padding: 24px 24px 24px 24px;
  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
    .et-icon-box-title {
      color: #ffffff;
    }
    .et-icon-box-content {
      color: #ffffff;
    }
    .ghost-icon {
      color: #ffffff;
    }
    .et-icon {
      color: #ffffff;
    }
  }
  .et-icon-box-title {
    color: #212121;
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }
  .ghost-icon {
    color: var(--color1);
  }
}
#et-icon-box-363400.link {
  &:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}
.vc_custom_1556032486903 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
}
.vc_custom_1557567416823 {
  padding-right: 24px !important;
  padding-left: 12px !important;
}
.vc_custom_1558537155365 {
  padding-top: 24px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}
.vc_custom_1556032951115 {
  padding-top: 32px !important;
  background-image: url(../../images/mm1-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
.vc_custom_1556096552381 {
  padding-top: 0px !important;
  padding-left: 8px !important;
}
.vc_custom_1556096483078 {
  padding-top: 0px !important;
}
.vc_custom_1556033148187 {
  padding-top: 32px !important;
  background-image: url(../../images/mm2-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}
.vc_custom_1556096586351 {
  padding-top: 0px !important;
}
.vc_custom_1556096591583 {
  margin-top: 60px !important;
  margin-bottom: 80px !important;
  padding-top: 0px !important;
  padding-left: 270px !important;
}
.vc_custom_1556101384268 {
  padding-top: 32px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}
.vc_custom_1558686218213 {
  padding-top: 32px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}
.vc_custom_1556105154636 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}
.vc_custom_1556105144571 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}
.vc_custom_1556105304439 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}
.vc_custom_1556113888436 {
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
}
.vc_custom_1558537195205 {
  padding-top: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}
.vc_custom_1558537258890 {
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}
.vc_custom_1556806829753 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
}
// #megamenu-562 {
//   width: 1200px;
//   max-width: 1200px;
// }
.et-gap-891547 {
  height: 8px;
}
.et-gap-802896 {
  height: 12px;
}
.vc_custom_1558013270805 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 12px !important;
  padding-right: 24px !important;
  padding-bottom: 0px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
  border-radius: 4px !important;
}
.vc_custom_1558013669178 {
  padding-left: 24px !important;
}
#et-ad-57453 {
  .background {
    background-image: url(../../images/banner_back_1-1.jpg);
    background-color: var(--color1);
    background-position: left bottom;
  }
  .et-ad-img {
    left: 12px;
    top: 185px;
  }
  padding: 48px 40px 48px 40px;
  margin: 0px 0px 0px 0px;
}
#et-heading-514354 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
.et-gap-787046 {
  height: 314px;
}
.et-gap-943262 {
  height: 270px;
}
#et-button-911778 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #000000;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}
#et-ad-928278 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -48px;
    top: -12px;
  }
}
.et-gap-219039 {
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
  height: 16px;
}
.et-gap-217795 {
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
  height: 24px;
}
.et-gap-70546 {
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
  height: 12px;
}
#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
    color: var(--text-color1);
  }
}
#et-heading-468490 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
.vc_custom_1557747880457 {
  padding-left: 200px !important;
  padding-left: 200px !important;
  padding-left: 200px !important;
}
.vc_custom_1557747871950 {
  padding-right: 24px !important;
  padding-right: 24px !important;
  padding-right: 24px !important;
}
#et-ad-721275 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -20px;
    top: -24px;
  }
}
#et-heading-376608 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
#et-ad-890145 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: 25px;
    top: 15px;
  }
}
#et-heading-138430 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
    color: var(--color1);
    color: var(--color1);
  }
}
.vc_custom_1557753152317 {
  padding-left: 260px !important;
}
.vc_custom_1557753145459 {
  padding-right: 24px !important;
}
#et-ad-448273 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: 0px;
    top: 15px;
  }
}
.vc_custom_1557750380894 {
  padding-left: 200px !important;
}
.vc_custom_1557750372684 {
  padding-right: 24px !important;
}
#et-ad-206743 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: 25px;
    top: 15px;
  }
}
.vc_custom_1557753226247 {
  padding-left: 260px !important;
}
.vc_custom_1557753219244 {
  padding-right: 24px !important;
}
#et-ad-736809 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -40px;
    top: 12px;
  }
}
.et-gap-921903 {
  height: 16px;
  height: 16px;
}
.et-gap-316697 {
  height: 24px;
  height: 24px;
}
#et-heading-835415 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
    color: var(--text-color1);
  }
}
#et-heading-544962 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
#et-button-121510 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    color: #ffffff;
  }
  .regular {
    background-color: var(--button-color1);
    background-color: var(--button-color1);
  }
  .hover {
    background-color: #d52b27;
    background-color: #d52b27;
  }
  .et-ink {
    color: #000000;
    background-color: #ffffff;
    color: #000000;
    background-color: #ffffff;
  }
}
.vc_custom_1557747384858 {
  padding-left: 200px !important;
}
.vc_custom_1557747415359 {
  padding-right: 24px !important;
}
#et-ad-629105 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -150px;
    top: -24px;
  }
}
#et-heading-268438 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-788616 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
.vc_custom_1557745320933 {
  padding-left: 350px !important;
}
.vc_custom_1557744417001 {
  padding-right: 24px !important;
}
#et-ad-90449 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -120px;
  }
}
#et-heading-561293 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
.vc_custom_1557748075520 {
  padding-left: 200px !important;
}
.vc_custom_1557748066382 {
  padding-right: 24px !important;
}
#et-ad-203879 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -40px;
    top: -24px;
  }
}
#et-heading-899091 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
#et-ad-360815 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -70px;
    top: 12px;
  }
}
#et-heading-312565 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
.vc_custom_1557747754080 {
  padding-left: 200px !important;
}
.vc_custom_1557747746219 {
  padding-right: 24px !important;
}
#et-ad-863298 {
  border-radius: 8px;
  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }
  .et-ad-img {
    left: -40px;
    top: -12px;
  }
}
#et-heading-409461 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--color1);
  }
}
.vc_custom_1557572368039 {
  padding-left: 200px !important;
}
.vc_custom_1557572387142 {
  padding-right: 24px !important;
}
.et-icon-680651 {
  color: #ffffff;
  border-radius: 0px;
}
.et-gap-439281 {
  height: 12px;
}
.et-gap-308481 {
  height: 12px;
}
.et-gap-883750 {
  height: 24px;
}
.et-gap-536909 {
  height: 8px;
}
.et-gap-663926 {
  height: 32px;
}
.et-gap-913975 {
  height: 32px;
}
.et-gap-580697 {
  height: 16px;
}
.et-gap-299018 {
  height: 16px;
}
.et-gap-688443 {
  height: 42px;
}
.et-gap-409036 {
  height: 42px;
}
#et-heading-58211 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-375874 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #ffffff;
  font-weight: 500;
  line-height: 26px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #ffffff;
  }
}
#et-heading-123899 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #000000;
  }
}
#et-heading-126946 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: var(--text-color1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: var(--text-color1);
  }
}
#et-heading-299385 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #bdbdbd;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #bdbdbd;
  }
}
#et-heading-831981 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
    padding: 0px 0px 0px 0px;
  }
  color: #212121;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  margin: 0px 0px 0px 0px;
  a {
    color: #212121;
  }
}
#et-mailchimp-576420 {
  .field {
    color: var(--text-color1);
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    &:focus {
      color: var(--text-color1);
      background-color: #ffffff;
      border: 1px solid #bdbdbd;
    }
  }
  &::placeholder {
    color: var(--text-color1);
  }
  .send-div {
    .button {
      color: #000000;
      background-color: var(--button-color1);
      &:hover {
        color: #ffffff;
        background-color: #212121 !important;
      }
    }
    &:hover {
      .sending {
        color: #ffffff;
        background-color: #212121 !important;
      }
    }
  }
  .sending {
    color: #000000;
    background-color: var(--button-color1);
  }
}
#et-icon-box-893709 {
  .et-icon-box-title {
    color: var(--text-color1);
  }
  .et-icon-box-content {
    color: var(--text-color1);
  }
  .et-icon {
    color: #ffd800;
    border-radius: 0px;
  }
  .ghost-icon {
    color: #ffd800;
  }
  padding: 0px 0px 0px 0px;
}
#et-social-links-397572 {
  a {
    color: #9a9a9a;
    background-color: transparent;
    box-shadow: none;
    margin-right: 0;
    &:hover {
      color: #212121;
      background-color: transparent;
      box-shadow: none;
    }
  }
}
#et-icon-list-501253 {
  .icon {
    color: var(--color1);
    border-radius: 0px;
  }
}
// .vc_custom_1558615059402 {
//   padding-top: 40px !important;
//   padding-bottom: 40px !important;
//   background: var(--color1) url(../../images/map_dark-1.jpg) !important;
//   background-position: center !important;
//   background-repeat: no-repeat !important;
//   background-size: cover !important;
// }
.vc_custom_1559292623224 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
  background: var(--color3) url(../../images/slider_pattern_white.png) !important;
  background-position: 0 0 !important;
  background-repeat: repeat !important;
}
.vc_custom_1559291692163 {
  padding-top: 24px !important;
  padding-bottom: 16px !important;
  background-color: var(--color2) !important;
}
.vc_custom_1559292758492 {
  padding-right: 32px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.slider-rotate {
  img {
    position: relative;
    transform-origin: center;
    animation: sliderLayerRotate 50s infinite linear;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #et-ad-909984 {
    .et-ad-img {
      left: 450px;
    }
  }
  #et-ad-386840 {
    .et-ad-img {
      left: 450px;
    }
  }
  #et-ad-996057 {
    .et-ad-img {
      left: 220px;
    }
  }
  #et-ad-916825 {
    .et-ad-img {
      left: 160px;
      top: 34px;
    }
  }
  #et-ad-41747 {
    .et-ad-img {
      left: 170px;
      top: 32px;
    }
  }
  #et-ad-463409 {
    .et-ad-img {
      left: -50px;
      top: 32px;
    }
  }
  #et-ad-721275 {
    .et-ad-img {
      top: -12px;
    }
  }
  #et-ad-890145 {
    .et-ad-img {
      left: 35px;
      top: 30px;
    }
  }
  #et-ad-206743 {
    .et-ad-img {
      left: 25px;
      top: 25px;
    }
  }
  #et-ad-629105 {
    .et-ad-img {
      left: -300px;
      top: -24px;
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  #et-ad-909984 {
    .et-ad-img {
      left: 250px;
    }
  }
  #et-ad-386840 {
    .et-ad-img {
      left: 200px;
    }
  }
  #et-ad-996057 {
    .et-ad-img {
      left: 200px;
    }
  }
  #et-ad-916825 {
    .et-ad-img {
      left: 160px;
      top: -30px;
    }
  }
  #et-ad-41747 {
    .et-ad-img {
      left: 200px;
      top: 32px;
    }
  }
  #et-ad-463409 {
    .et-ad-img {
      left: -50px;
      top: 0px;
    }
  }
  .medium.grid {
    .blog-content {
      #loop-posts {
        .post {
          .post-title {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }
  #yith-quick-view-modal {
    .yith-wcqv-wrapper {
      width: 960px !important;
      height: 480px !important;
      margin-left: -480px !important;
      margin-top: -240px !important;
    }
  }
  #yith-quick-view-content {
    .summary {
      height: 480px !important;
    }
  }
  .background-position-right-center-tablet {
    .background {
      background-position: 120% center !important;
    }
  }
  .logo-size-normal {
    .vc_column-inner {
      background-position: left !important;
    }
  }
  .category-icon-list.et-icon-list {
    display: inline-block;
    + {
      .et-button {
        display: block;
        max-width: 216px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .homepage-3-banner-group {
    > .container {
      max-width: 60%;
    }
  }
}
@media only screen and (max-width: 479px) {
  #et-ad-909984 {
    .et-ad-img {
      left: 150px;
    }
  }
  #et-ad-386840 {
    .et-ad-img {
      left: 150px;
    }
  }
  #et-ad-996057 {
    .et-ad-img {
      left: 200px;
    }
  }
  #et-ad-916825 {
    .et-ad-img {
      left: 180px;
      top: 24px;
    }
  }
  #et-ad-41747 {
    .et-ad-img {
      left: 180px;
      top: 32px;
    }
  }
  #et-ad-463409 {
    .et-ad-img {
      left: -50px;
      top: 32px;
    }
  }
  #et-ad-928278 {
    .et-ad-img {
      left: 160px;
      top: -12px;
    }
  }
  #et-ad-721275 {
    .et-ad-img {
      left: 220px;
      top: -12px;
    }
  }
  #et-ad-890145 {
    .et-ad-img {
      left: 150px;
      top: 80px;
    }
  }
  #et-ad-448273 {
    .et-ad-img {
      left: -120px;
      top: 20px;
    }
  }
  #et-ad-206743 {
    .et-ad-img {
      left: 150px;
      top: 80px;
    }
  }
  #et-ad-736809 {
    .et-ad-img {
      left: 200px;
      top: -10px;
    }
  }
  #et-ad-629105 {
    .et-ad-img {
      left: -420px;
      top: -32px;
    }
  }
  #et-ad-90449 {
    .et-ad-img {
      left: 140px;
      top: 80px;
    }
  }
  #et-ad-203879 {
    .et-ad-img {
      left: 220px;
      top: -12px;
    }
  }
  #et-ad-360815 {
    .et-ad-img {
      left: 230px;
      top: -12px;
    }
  }
  #et-ad-863298 {
    .et-ad-img {
      left: -160px;
      top: -24px;
    }
  }
  .list {
    #loop-posts {
      .post {
        .post-title {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
    .et-shortcode-posts {
      .post {
        .post-title {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
  .post-title-section {
    .post-title {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .list {
    #loop-posts {
      .post {
        .post-title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    .et-shortcode-posts {
      .post {
        .post-title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  .full {
    #loop-posts {
      .post {
        .post-title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .background-position-right-center-tablet {
    .background {
      background-position: 0 center !important;
    }
  }
  .category-icon-list.et-icon-list {
    display: inline-block;
    + {
      .et-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .medium.grid {
    #loop-posts {
      .post {
        .post-title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    .et-shortcode-posts {
      .post {
        .post-title {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  .background-position-right-center-tablet {
    .background {
      background-position: 150% center !important;
    }
  }
  .category-icon-list.et-icon-list {
    display: inline-block;
    + {
      .et-button {
        display: block;
        max-width: 216px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .list {
    #loop-posts {
      .post {
        .post-title {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .et-shortcode-posts {
      .post {
        .post-title {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
  .full {
    #loop-posts {
      .post {
        .post-title {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .post-title-section {
    .post-title {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .full {
    #loop-posts {
      .post {
        .post-title {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1279px) {
  .margin-top-1-tablet {
    margin-top: -1px;
  }
}
@media only screen and (min-width: 1280px) {
  #yith-quick-view-modal {
    .yith-wcqv-wrapper {
      width: 960px !important;
      height: 480px !important;
      margin-left: -480px !important;
      margin-top: -240px !important;
      width: 960px !important;
      height: 480px !important;
      margin-left: -480px !important;
      margin-top: -240px !important;
    }
  }
  #yith-quick-view-content {
    .summary {
      height: 480px !important;
    }
  }
  .homepage-3-banner-group {
    > .container {
      > .wpb_column {
        &:last-child {
          > .vc_column-inner {
            margin-left: -1px;
          }
        }
      }
    }
  }
  .hot-deals-of-the-day {
    .woo-single-product {
      height: 543px;
    }
  }
}
.et-gap-12357 {
  height: 1px;
}

.et-gap-630216,
.et-gap-813797 {
  height: 32px;
}

.et-gap-8893 {
  height: 1px;
}

.et-gap-605007 {
  height: 40px;
}

.et-separator-751501 .line {
  margin-top: 0px;
  margin-bottom: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
}

.et-separator-375960 .line {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom-color: var(--color1);
  border-bottom-style: solid;
  height: 2px;
  border-bottom-width: 2px;
}

.et-separator-653406 {
  .line {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom-color: #ffd800;
    border-bottom-style: dashed;
    border-bottom-width: 2px;
  }

  &.active .line {
    height: 2px;
  }
}

.et-icon-separator-412367,
.et-icon-separator-14611,
.et-icon-separator-38568,
.et-icon-separator-34014,
.et-icon-separator-361220,
.et-icon-separator-671419,
.et-icon-separator-743307,
.et-icon-separator-97476,
.et-icon-separator-840014 {
  margin-top: 24px;
  margin-bottom: 24px;

  .line {
    background-color: #e0e0e0;
    width: 120px;
    height: 1px;
  }

  .icon {
    color: var(--color1);
  }
}

.et-gap-inline-470773 {
  width: 8px;
}
