@import 'config';
// @import '~bootstrap/dist/css/bootstrap.min.css';

$primary: $color1;
$secondary: $color2;

// Required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Optional
// @import '~bootstrap/scss/reboot';
// @import '~bootstrap/scss/type';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/list-group';

@import '~react-widgets/dist/css/react-widgets';
@import './theme/style';

@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/custom-forms';
@import '~react-toastify/dist/ReactToastify.css';

@import './override.scss';
