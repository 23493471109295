body,
input,
select,
pre,
code,
kbd,
samp,
dt,
#cancel-comment-reply-link,
.box-item-content,
textarea,
.widget_price_filter .price_label,
.demo-icon-pack span:after {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0px;
  // color: var(--text-color1);
}

.header-login .login-title,
.cart-contents {
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  letter-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.woocommerce-page
  #et-content
  .shop_table
  .product-name
  > a:not(.yith-wcqv-button),
.woocommerce-Tabs-panel .shop_attributes th,
#reply-title,
.et-circle-progress .circle-content,
.et-timer .timer-count {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
}

.et-pricing-table {
  .currency,
  .price {
    font-family: Montserrat;
    text-transform: none;
    font-weight: 700;
    letter-spacing: 0px;
    color: #212121;
  }
}

.et-counter .counter,
.et-progress .percent,
.error404-default-subtitle,
.yith-woocompare-widget ul.products-list li .title,
.woocommerce-MyAccount-navigation ul li a,
.woocommerce-tabs .tabs li a {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
}

.product .summary .price {
  font-family: Montserrat;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0px;
}

.widget_layered_nav ul li a,
.widget_nav_menu ul li a,
.widget_product_categories ul li a,
.widget_categories ul li a,
.post-single-navigation a,
.widget_pages ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_recent_entries ul li a,
.widget_rss ul li a,
.widget_icl_lang_sel_widget li a,
.recentcomments a,
.widget_product_search form button:before,
.page-content-wrap .widget_shopping_cart .cart_list li .remove {
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  color: #212121;
}

.woocommerce-page
  #et-content
  .shop_table
  .product-name
  > a:not(.yith-wcqv-button),
.widget_et_recent_entries .post-title a,
.widget_products .product_list_widget > li .product-title a,
.widget_recently_viewed_products .product_list_widget > li .product-title a,
.widget_recent_reviews .product_list_widget > li .product-title a,
.widget_top_rated_products .product_list_widget > li .product-title a {
  color: #212121 !important;
}

.page-content-wrap .widget_shopping_cart .cart-product-title a,
.et-circle-progress .percent {
  color: #212121;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 20px;
  line-height: 28px;
}

h6 {
  font-size: 18px;
  line-height: 26px;
}

.widgettitle {
  font-size: 20px;
  line-height: 28px;
}

.woocommerce-Tabs-panel h2,
.shop_table .product-name > a:not(.yith-wcqv-button),
.widget_layered_nav ul li a,
.widget_nav_menu ul li a,
.widget_product_categories ul li a,
.yith-woocompare-widget ul.products-list li .title {
  font-size: 18px;
  line-height: 26px;
}

#reply-title,
.woocommerce h2 {
  font-size: 24px;
  line-height: 32px;
}

.et-timer .timer-count {
  font-size: 48px;
  line-height: 56px;
}

.et-circle-progress .percent {
  font-size: 48px;
  line-height: 48px;
}

#loop-posts .post-title {
  &:hover,
  a:hover {
    color: var(--color1);
  }
}

.et-shortcode-posts .post-title {
  &:hover,
  a:hover {
    color: var(--color1);
  }
}

.loop-product .post-title {
  &:hover,
  a:hover {
    color: var(--color1);
  }
}

.post-social-share > .social-links > a:hover,
.related-posts .post .post-title a:hover,
.product .summary .price ins {
  color: var(--color1);
}

.page-content-wrap .widget_shopping_cart .cart-product-title {
  a:hover,
  &:hover a {
    color: var(--color1);
  }
}

.widget_products .product_list_widget > li > a:hover .product-title,
.widget_recently_viewed_products
  .product_list_widget
  > li
  > a:hover
  .product-title,
.widget_recent_reviews .product_list_widget > li > a:hover .product-title,
.widget_top_rated_products .product_list_widget > li > a:hover .product-title {
  color: var(--color1);
}

.search-posts .post-title {
  a:hover,
  &:hover a {
    color: var(--color1);
  }
}

.et-testimonial-item .rating span,
.plyr--full-ui input[type='range'],
.comment-meta .comment-date-time a:hover,
.comment-author a:hover,
.comment-content .edit-link a a,
#cancel-comment-reply-link:hover,
.woocommerce-review-link {
  color: var(--color1);
}

.yith-woocompare-widget ul.products-list li {
  .title:hover,
  .remove:hover {
    color: var(--color1);
  }
}

.product .price,
.widget .woocommerce-Price-amount,
.star-rating {
  color: var(--color1);
}

.comment-form-rating a {
  color: var(--color1);

  &:after {
    color: var(--color1);
  }
}

.widget_nav_menu ul li.current-menu-item a,
.dgwt-wcas-sp .woocommerce-Price-amount,
.dgwt-wcas-pd-price .woocommerce-Price-amount,
.post-meta > *,
.enovathemes-filter .filter.active {
  color: var(--color1);
}

.shop-cat-list a {
  &:hover,
  &.active {
    color: var(--color1);
  }
}

.post-single-navigation a:hover,
.post-meta a:hover,
.widget_et_recent_entries .post-title:hover a,
.widget_categories ul li a:hover,
.widget_pages ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_layered_nav ul li a:hover,
.widget_nav_menu ul li a:hover {
  color: var(--color1) !important;
}

.widget_product_categories ul li {
  a:hover,
  &.current-cat > a {
    color: var(--color1) !important;
  }
}

.widget_recent_entries ul li a:hover,
.widget_rss ul li a:hover,
.widget_icl_lang_sel_widget li a:hover,
.widget_products .product_list_widget > li .product-title:hover a,
.widget_recently_viewed_products
  .product_list_widget
  > li
  .product-title:hover
  a,
.widget_recent_reviews .product_list_widget > li .product-title:hover a,
.widget_top_rated_products .product_list_widget > li .product-title:hover a,
.recentcomments a:hover,
#yith-quick-view-close:hover,
.page-content-wrap .widget_shopping_cart .cart_list li .remove:hover,
.woocommerce-page
  #et-content
  .shop_table
  .product-name
  > a:not(.yith-wcqv-button):hover,
.product-layout-single .summary .yith-wcwl-add-to-wishlist a:hover {
  color: var(--color1) !important;
}

.yith-wcwl-add-to-wishlist {
  a {
    &:hover,
    &.added {
      color: var(--color1) !important;
    }
  }

  .hide + .show a {
    color: var(--color1) !important;
  }
}

.loop-product .wooscp-btn:hover,
.custom-product .wooscp-btn:hover,
.loop-product .wooscp-btn.wooscp-btn-added,
.custom-product .wooscp-btn.wooscp-btn-added,
.widget_categories ul li a:hover:after,
.widget_pages ul li a:hover:after,
.widget_archive ul li a:hover:after,
.widget_meta ul li a:hover:after {
  color: var(--color1) !important;
}

.widget_layered_nav ul li {
  a:hover:after,
  &.chosen > a:after {
    color: var(--color1) !important;
  }
}

.widget_rating_filter ul li {
  a:hover:after,
  &.chosen > a:after {
    color: var(--color1) !important;
  }
}

.dgwt-wcas-pd-rating .star-rating,
.woocommerce-MyAccount-navigation li.is-active a,
.post .post-meta,
.related-posts-title,
.comment-reply-title,
.comments-title,
.upsells > h4,
.crosssells > h4,
.related > h4,
#reply-title,
.et-person .name {
  color: var(--color1) !important;
}

.post-read-more:after,
.comment-reply-link:after {
  background-color: var(--color1);
}

.enovathemes-navigation {
  a:hover,
  .current {
    background-color: var(--color1);
  }
}

.post-sticky {
  background-color: var(--color1);
}

.post-media {
  .flex-direction-nav li a:hover {
    background-color: var(--color1);
  }

  .flex-control-nav li a {
    &:hover,
    &.flex-active {
      background-color: var(--color1);
    }
  }
}

.slick-dots li {
  button:hover,
  &.slick-active button {
    background-color: var(--color1);
  }
}

.owl-carousel .owl-nav > *:hover,
.overlay-flip-hor .overlay-hover .post-image-overlay,
.overlay-flip-ver .overlay-hover .post-image-overlay,
.image-move-up .post-image-overlay,
.image-move-down .post-image-overlay,
.image-move-left .post-image-overlay,
.image-move-right .post-image-overlay,
.overlay-image-move-up .post-image-overlay,
.overlay-image-move-down .post-image-overlay,
.overlay-image-move-left .post-image-overlay,
.overlay-image-move-right .post-image-overlay,
.product .onsale,
.product-quick-view:hover {
  background-color: var(--color1);
}

.yith-woocompare-widget a {
  &.compare,
  &.clear-all {
    background-color: var(--color1);
  }
}

.added_to_cart,
.woocommerce-store-notice.demo_store,
.shop_table .product-remove a:hover,
.et-accordion .toggle-title.active,
.tabset .tab.active,
.et-mailchimp input[type='text'] + .after,
.owl-carousel .owl-dots > .owl-dot.active,
.et-pricing-table .label,
.mob-menu-toggle-alt,
.full #loop-posts .format-link .post-body-inner-wrap,
.single-post-page > .format-link .format-container {
  background-color: var(--color1);
}

.plyr--audio .plyr__control {
  &.plyr__tab-focus,
  &:hover,
  &[aria-expanded='true'] {
    background-color: var(--color1);
  }
}

.plyr--video .plyr__control {
  &.plyr__tab-focus,
  &:hover,
  &[aria-expanded='true'] {
    background-color: var(--color1);
  }
}

.plyr__control--overlaid {
  &:focus,
  &:hover {
    background-color: var(--color1);
  }
}

.plyr__menu__container
  .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background-color: var(--color1);
}

.woocommerce-tabs .tabs li {
  &.active a,
  a:hover {
    background-color: var(--color1);
  }
}

.et-image .curtain,
.et-breadcrumbs a:after,
.nivo-lightbox-prev:hover,
.nivo-lightbox-next:hover,
.nivo-lightbox-close:hover,
.added_to_cart:after,
.et-pricing-table .plan:after,
.et-video .modal-video-poster:before,
.widget_title:before,
.widgettitle:before {
  background-color: var(--color1);
}

.product .post-image-overlay > {
  .overlay-read-more:hover,
  .post-image-overlay-content > .overlay-read-more:hover {
    background-color: var(--color1);
  }
}

.widget_price_filter .ui-slider .ui-slider-handle,
.widget-area .dgwt-wcas-sf-wrapp button.dgwt-wcas-search-submit,
.post-date-side,
.single-product-progress .bar,
.enovathemes-filter .filter-toggle {
  background-color: var(--color1);
}

.shop-cat-list-title span {
  &:before,
  &:after {
    background-color: var(--color1);
  }
}

.mejs-controls .mejs-time-rail .mejs-time-current {
  background-color: var(--color1) !important;
}

.slick-slider {
  .slick-prev:hover,
  .slick-next:hover {
    background-color: var(--color1) !important;
  }
}

.widget_tag_cloud .tagcloud a:after,
.widget_product_tag_cloud .tagcloud a:after,
.widget_price_filter .ui-slider-horizontal .ui-slider-range,
#cboxClose:hover,
.wooscp-area .wooscp-inner .wooscp-bar .wooscp-bar-btn {
  background-color: var(--color1) !important;
}

.plyr--video .plyr__controls {
  background: rgba(224, 92, 11, 0.5) !important;
}

.widget-area .dgwt-wcas-sf-wrapp button.dgwt-wcas-search-submit:hover {
  background-color: rgb(204, 72, -9) !important;
}

ul.chat li:nth-child(2n + 2) > p {
  background-color: rgba(224, 92, 11, 0.1);
  color: var(--color1) !important;
}

.plyr__control--overlaid {
  background-color: var(--color1);
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 8px 24px 0 rgba(224, 92, 11, 0.5);
}

#yith-wcwl-popup-message {
  color: var(--color1) !important;
  box-shadow: inset 0 0 0 1px var(--color1);
}

.post-image-overlay > {
  .overlay-read-more:hover,
  .post-image-overlay-content > .overlay-read-more:hover {
    color: var(--color1) !important;
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}

.ajax-add-to-cart-loading .circle-loader,
.yith-wcwl-add-to-wishlist a:after {
  border-left-color: var(--color1);
}

.ajax-add-to-cart-loading .load-complete,
.widget-area .dgwt-wcas-sf-wrapp input[type='search'].dgwt-wcas-search-input {
  border-color: var(--color1) !important;
}

.ajax-add-to-cart-loading .checkmark:after {
  border-right: 1px solid var(--color1);
  border-top: 1px solid var(--color1);
}

blockquote {
  background-color: rgba(224, 92, 11, 0.05) !important;
  border-left: 8px solid var(--color1) !important;
  color: var(--color1) !important;
}

.wp-block-quote[style*='text-align:right'] {
  border-right: 8px solid var(--color1) !important;
}

.et-pricing-table.highlight-true .pricing-table-inner {
  border-color: var(--color1) !important;
}

.counter-moving-child:before {
  border-color: var(--color1);
}

.highlight-true .testimonial-content {
  box-shadow: inset 0 0 0 1px var(--color1);
  border-color: var(--color1);

  &:after {
    border-color: var(--color1) transparent transparent transparent;
  }
}

.woocommerce-product-gallery .flex-control-nav li img.flex-active {
  box-shadow: 0 0 0 1px var(--color1);
}

.enovathemes-navigation {
  a:hover,
  .current {
    box-shadow: inset 0 0 0 1px var(--color1);
  }
}

.widget_categories ul li a:hover:before,
.widget_pages ul li a:hover:before,
.widget_archive ul li a:hover:before,
.widget_meta ul li a:hover:before,
.widget_nav_menu ul li a:hover:before {
  box-shadow: inset 0 0 0 2px var(--color1);
}

.widget_layered_nav ul li {
  a:hover:before,
  &.chosen > a:before {
    box-shadow: inset 0 0 0 2px var(--color1);
  }
}

.widget_rating_filter ul li {
  a:hover:before,
  &.chosen > a:before {
    box-shadow: inset 0 0 0 2px var(--color1);
  }
}

.overlay-fall .overlay-hover .post-image-overlay {
  background-color: var(--color1);
}

#header-menu-default > .menu-item.depth-0 > .mi-link .txt:after {
  border-bottom-color: var(--color1);
}

a:hover,
.comment-content .edit-link a a:hover,
.woocommerce-review-link:hover,
.product_meta a:hover {
  color: var(--color1);
}

.widget_tag_cloud .tagcloud a:hover:after,
.widget_product_tag_cloud .tagcloud a:hover:after {
  background-color: var(--color1) !important;
}

#to-top {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  font-size: 16px;
  border-radius: 4px;
  color: #ffffff;
  background-color: var(--color1);

  &:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .et-ink {
    background-color: #000000;
  }
}

html,
#gen-wrap {
  background-color: #ffffff;
}

.site-loading {
  background-color: #ffffff;

  .site-loading-bar:after {
    background-color: var(--color1);
  }
}

.product .summary .woocommerce-product-rating .star-rating,
.et-testimonial .author-info-wrapper .rating span {
  color: #ffd800;
}

.single-product-wrapper .onsale {
  background-color: var(--button-color1);
  color: #000000;
}

textarea,
select {
  color: var(--color2);
  background-color: #ffffff;
  border-color: var(--color3);
}

input {
  &[type='date'],
  &[type='datetime'],
  &[type='datetime-local'],
  &[type='email'],
  &[type='month'],
  &[type='number'],
  &[type='password'],
  &[type='search'],
  &[type='tel'],
  &[type='text'],
  &[type='time'],
  &[type='url'],
  &[type='week'],
  &[type='file'] {
    color: var(--color2);
    background-color: #ffffff;
    border-color: var(--color3);
  }
}

.tech-page-search-form .search-icon,
.widget_search form input[type='submit']#searchsubmit + .search-icon,
.widget_product_search form input[type='submit'] + .search-icon {
  color: var(--text-color1) !important;
}

.select2-container--default .select2-selection--single {
  color: var(--text-color1) !important;
  background-color: #ffffff !important;
  border-color: #e0e0e0 !important;

  .select2-selection__rendered {
    color: var(--text-color1) !important;
  }
}

.select2-dropdown,
.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #ffffff !important;
}

textarea:focus,
select:focus {
  color: var(--color2);
  // border-color: #ffd800;
  background-color: #ffffff;
}

// input {
//   &[type='date']:focus,
//   &[type='datetime']:focus,
//   &[type='datetime-local']:focus,
//   &[type='email']:focus,
//   &[type='month']:focus,
//   &[type='number']:focus,
//   &[type='password']:focus,
//   &[type='search']:focus,
//   &[type='tel']:focus,
//   &[type='text']:focus,
//   &[type='time']:focus,
//   &[type='url']:focus,
//   &[type='week']:focus,
//   &[type='file']:focus {
//     color: var(--text-color1);
//     border-color: #ffd800;
//     background-color: #ffffff;
//   }
// }

.tech-page-search-form [type='submit']#searchsubmit:hover + .search-icon,
.widget_search form input[type='submit']#searchsubmit:hover + .search-icon,
.widget_product_search form input[type='submit']:hover + .search-icon {
  color: var(--text-color1) !important;
}

.select2-container--default .select2-selection--single {
  &:focus {
    color: var(--text-color1) !important;
    border-color: #ffd800 !important;
    background-color: #ffffff !important;
  }

  .select2-selection__rendered:focus {
    color: var(--text-color1) !important;
  }
}

.select2-dropdown:focus,
.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  background-color: #ffffff !important;
}

input {
  &[type='button'],
  &[type='reset'],
  &[type='submit'] {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
  }
}

// button:not(.plyr__control),
// .post-read-more,
// .comment-reply-link,
// a.checkout-button,
// .return-to-shop a {
button.theme-button,
.return-to-shop a {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
}

.wishlist_table {
  .product-add-to-cart a,
  .yith-wcqv-button {
    color: #000000;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
  }
}

a.woocommerce-button {
  color: #000000;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
}

.product {
  // .button,
  .added_to_cart {
    color: var(--button-color1);
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
  }
}

#page-links > a,
.edit-link a,
.page-content-wrap .woocommerce-mini-cart__buttons > a,
.woocommerce .wishlist_table td.product-add-to-cart a,
.woocommerce-message .button,
a.error404-button {
  color: var(--button-color1);
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
}

.yith-woocompare-widget a {
  &.clear-all,
  &.compare {
    color: var(--button-color1);
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
    background-color: var(--button-color1);
  }
}

.vin-result-btn,
.pricing-table-footer .et-button {
  color: var(--button-color1);
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
}

.woocommerce-message .button {
  color: var(--button-color1);
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
  background-color: var(--button-color1);
  // color: #000000 !important;
}

.et-button,
.et-ajax-loader,
.woocommerce-mini-cart__buttons > a {
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
}

.yith-woocompare-widget a {
  &.compare,
  &.clear-all {
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0px;
  }
}

.added_to_cart,
.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.post-tags-single a {
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0px;
}

input {
  &[type='button']:hover,
  &[type='reset']:hover,
  &[type='submit']:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}

// button:hover,
// .post-read-more:hover,
// .comment-reply-link:hover,
// .product .button:hover {
//   color: #ffffff !important;
//   background-color: #212121;
// }

.yith-woocompare-widget a {
  &.compare:hover,
  &.clear-all:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}

a.checkout-button:hover,
.return-to-shop a:hover {
  color: #ffffff !important;
  background-color: #212121;
}

.wishlist_table {
  .product-add-to-cart a:hover,
  .yith-wcqv-button:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}

a.woocommerce-button:hover,
.woocommerce-mini-cart__buttons > a:hover,
#page-links > a:hover,
.edit-link a:hover,
.et-ajax-loader:hover,
.page-content-wrap .woocommerce-mini-cart__buttons > a:hover,
.woocommerce .wishlist_table td.product-add-to-cart a:hover,
.error404-button:hover {
  color: #ffffff !important;
  background-color: #212121;
}

.yith-woocompare-widget a {
  &.clear-all:hover,
  &.compare:hover {
    color: #ffffff !important;
    background-color: #212121;
  }
}

.vin-result-btn:hover,
.pricing-table-footer .et-button:hover {
  color: #ffffff !important;
  background-color: #212121;
}

.woocommerce-message .button:hover {
  color: #ffffff !important;
  background-color: #212121;
  color: #ffffff !important;
}

.et-ajax-loader.loading:hover:after {
  border: 2px solid #ffffff;
}

#loop-posts .post-title,
.et-shortcode-posts .post-title {
  min-height: 0px;
}

.loop-product .post-title {
  min-height: 43px;
  max-height: 43px;
  overflow: hidden;
}

#et-heading-575082 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-weight: 700;
  line-height: 48px;
  text-transform: none;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-53532 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #212121;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-transform: none;

  a {
    color: #212121;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-470597 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-239314 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-522694 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-94638 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-218160 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-weight: 700;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-750054 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

.et-separator-89771 .line {
  margin-top: 16px;
  margin-bottom: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
}

.et-gap-112034 {
  height: 32px;
}

.et-gap-880736 {
  height: 16px;
}

.et-gap-200657 {
  height: 40px;
}

.et-gap-340714,
.et-gap-27552 {
  height: 24px;
}

.et-gap-500244,
.et-gap-629933 {
  height: 32px;
}

#et-image-177465 .curtain {
  background-color: var(--color1);
}

#et-icon-box-container-8390 {
  background-color: #e0e0e0;
  min-height: 0 !important;
}

#et-icon-box-817467 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title {
    color: #000000;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-icon-box-296608 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title {
    color: #000000;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-icon-box-670943 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title {
    color: #000000;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-icon-box-131198 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title {
    color: #000000;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-icon-box-278967 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title {
    color: #000000;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-icon-box-326753 {
  background-color: #ffffff;

  &:hover,
  &.link:hover {
  }

  .et-icon-box-title,
  .et-icon-box-content {
    color: #000000;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover .et-icon {
  }

  padding: 32px 32px 32px 32px;
}

#et-counter-363333,
#et-counter-506027,
#et-counter-544389,
#et-counter-536980 {
  .counter-value {
    color: var(--color1);

    > * {
      color: var(--color1);
    }
  }

  .counter-title {
    color: #000000;
  }
}

.header {
  .vc-row-504195 {
    height: 64px;

    .hbe {
      line-height: 63px;
    }
  }

  .vc-row-770266 {
    height: 56px;

    .hbe {
      line-height: 55px;
    }
  }

  &.sticky-true.active .vc-row-770266 {
    height: 56px;

    .hbe {
      line-height: 55px;
      height: 56px;
    }
  }
}

#header-woo-search-form-885237 {
  .dgwt-wcas-search-submit {
    color: #ffffff;
    background-color: #000000;
    box-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #000000;
      box-shadow: none;
    }
  }

  width: 960px;

  .dgwt-wcas-search-wrapp {
    height: 48px;
  }

  input.dgwt-wcas-search-input {
    width: 960px;
    color: var(--text-color1);
    background-color: #ffffff;
    border-color: #ffffff;
  }

  margin: -1px 0px 0px 0px;
}

#header-icon-40835,
#header-icon-91751 {
  .hicon {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
  }

  &:hover .hicon {
    color: #eeeeee;
    box-shadow: none;
  }

  .hicon {
    margin: 0px 0px 0px 0px;
  }
}

.header-vertical-separator-722008 {
  .line {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-bottom-style: solid;
    height: 32px;
    border-bottom-width: 32px;
    width: 1px;
  }

  margin: 0px 8px 0px 8px;
}

// #mobile-toggle-954982 {
//   color: #ffffff;
//   background-color: transparent;
//   box-shadow: none;

//   &:hover {
//     color: #eeeeee;
//     background-color: transparent;
//     box-shadow: none;
//   }
// }

// #mobile-container-toggle-954982 {
//   margin: 0px 4px 0px 0px;
// }

#header-logo-115569 {
  .logo,
  .sticky-logo {
    max-height: 20px;
  }

  margin: 0px 0px 0px 8px;
}

#header-logo-880392 {
  .logo {
    max-height: 26px;
  }

  margin: 16px 0px 0px 12px;
}

// #mobile-container-814131 {
//   color: var(--text-color1);
//   background-color: var(--color3);
//   padding: 16px 0px 32px 0px;
// }

#mobile-close-856662 {
  color: #bdbdbd;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    color: #212121;
    box-shadow: none;
  }
}

#mobile-container-close-856662 {
  margin: 12px 8px 0px 0px;
}

.et-gap-549073 {
  height: 16px;
}

.et-gap-654213 {
  height: 8px;
}

#et-heading-501901,
#et-heading-567116,
#et-heading-800854 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 16px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

// #mobile-menu-324351 {
//   > .menu-item > a {
//     color: #212121;
//     font-size: 16px;
//     line-height: 26px;
//     font-weight: 600;
//     letter-spacing: 0px;
//     text-transform: none;

//     &:hover {
//       background-color: transparent;
//       color: var(--color1);
//     }
//   }

//   .menu-item > a:after {
//     background-color: #e0e0e0;
//   }

//   > .menu-item {
//     &:last-child:after {
//       background-color: #e0e0e0;
//     }

//     .sub-menu .menu-item > a {
//       color: var(--text-color1);
//       font-size: 16px;
//       line-height: 26px;
//       font-weight: 600;
//       text-transform: none;

//       &:hover {
//         color: var(--color1);
//         background-color: transparent;
//       }
//     }
//   }
// }

#mobile-menu-container-324351 {
  margin: 0px 0px 0px 0px;
}

#mobile-menu-456260 {
  > .menu-item > a {
    color: #212121;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: 0px;
    text-transform: none;

    &:hover {
      background-color: transparent;
      color: var(--color1);
    }
  }

  .menu-item > a:after {
    background-color: #e0e0e0;
  }

  > .menu-item {
    &:last-child:after {
      background-color: #e0e0e0;
    }

    .sub-menu .menu-item > a {
      color: var(--text-color1);
      font-size: 16px;
      line-height: 26px;
      font-weight: 600;
      text-transform: none;

      &:hover {
        color: var(--color1);
        background-color: transparent;
      }
    }
  }
}

#mobile-menu-container-456260,
#header-slogan-317196,
#header-slogan-79048 {
  margin: 0px 0px 0px 0px;
}

#et-header-button-841587,
#et-header-button-23058 {
  > a {
    width: 296px;
    height: 48px;
    line-height: 47px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px !important;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #000000;
    border-radius: 4px;

    .icon {
      font-size: 18px !important;
      margin-right: 8px !important;
    }
  }

  &:hover > a {
    color: #ffffff;
  }

  > a {
    .regular {
      background-color: var(--button-color1);
    }

    .hover {
      background-color: #000000;
    }

    .et-ink {
      color: #000000;
      background-color: #ffffff;
    }
  }

  margin: 0px 0px 0px 12px;
}

.vc_custom_1559210763022 {
  border-bottom-width: 1px !important;
  background-color: var(--color1) !important;
  border-bottom-color: #bc5709 !important;
  border-bottom-style: solid !important;
}

.vc_custom_1559210719611 {
  background-color: var(--color1) !important;
}

.header {
  .vc-row-466261 {
    height: 48px;

    .hbe {
      line-height: 47px;
    }
  }

  .vc-row-271378 {
    z-index: 2;
    height: 96px;

    .hbe {
      line-height: 95px;
    }
  }

  .vc-row-617872 {
    z-index: 1;
    height: 56px;

    .hbe {
      line-height: 55px;
    }
  }
}

#header-icon-543050 {
  .hicon {
    color: var(--color1);
    background-color: transparent;
    box-shadow: none;
  }

  &:hover .hicon {
    color: var(--color1);
    box-shadow: none;
  }

  .hicon {
    margin: -2px 0px 0px 0px;
  }
}

#header-icon-26895,
#header-icon-802563 {
  .hicon {
    color: var(--color1);
    background-color: transparent;
    box-shadow: none;
  }

  &:hover .hicon {
    color: var(--color1);
    box-shadow: none;
  }

  .hicon {
    margin: -2px 0px 0px 24px;
  }
}

#header-icon-788327,
#header-icon-787254 {
  .hicon {
    color: #bdbdbd;
    background-color: transparent;
    font-size: 32px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
  }

  &:hover .hicon {
    color: #212121;
    box-shadow: none;
  }

  .hicon {
    margin: 0px 0px 0px 0px;
  }
}

#header-slogan-904693,
#header-slogan-887567,
#header-slogan-172029 {
  margin: 0px 0px 0px 0px;
}

#login-toggle-936942 {
  color: #bdbdbd;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    color: #ffffff;
    background-color: transparent;
    box-shadow: none;
  }
}

#header-login-936942 {
  .widget_reglog {
    color: var(--text-color1);
    background-color: #ffffff;

    a {
      color: var(--color1);
    }

    .input {
      color: var(--text-color1);
      background-color: #ffffff;
      box-shadow: inset 0 0 0 1px #e0e0e0;
    }

    .button {
      color: #000000;
      background-color: var(--button-color1);
      box-shadow: none;

      &:hover {
        color: #ffffff;
        background-color: #212121;
        box-shadow: none;
      }
    }
  }

  margin: 0px 0px 0px 0px;
}

.header-vertical-separator-144873 {
  .line {
    border-bottom-color: #424242;
    border-bottom-style: solid;
    height: 24px;
    border-bottom-width: 24px;
    width: 1px;
  }

  margin: 0px 8px 0px 8px;
}

.header-vertical-separator-441595 {
  .line {
    border-bottom-color: #424242;
    border-bottom-style: solid;
    height: 24px;
    border-bottom-width: 24px;
    width: 1px;
  }

  margin: 0px 8px 0px 16px;
}

.header-vertical-separator-855577 {
  .line {
    border-bottom-color: #e0e0e0;
    border-bottom-style: solid;
    height: 32px;
    border-bottom-width: 32px;
    width: 1px;
  }

  margin: 0px 16px 0px 20px;
}

#language-switcher-421048 {
  .language-toggle {
    color: #bdbdbd;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: transparent;
      box-shadow: none;
    }
  }

  ul {
    background-color: #ffffff;
    width: 140px;
  }

  .box-align-center ul {
    margin-left: -70px;
  }

  ul li a {
    color: var(--text-color1);
    background-color: #ffffff;

    &:hover {
      color: var(--color1);
      background-color: #f5f5f5;
    }
  }

  margin: 0px 0px 0px 0px;
}

#currency-switcher-365957 {
  .currency-toggle {
    color: #bdbdbd;

    &:hover {
      color: #ffffff;
    }
  }

  .highlighted-currency {
    color: var(--color1);
  }

  .currency-list {
    background-color: #ffffff;
    width: 80px;
  }

  &.box-align-center .currency-list {
    margin-left: -40px;
  }

  .currency-list a {
    color: var(--text-color1);
    background-color: #ffffff;

    &:hover {
      color: var(--color1);
      background-color: #f5f5f5;
    }
  }

  margin: 0px 0px 0px 0px;
}

#header-logo-617774 {
  .logo,
  .sticky-logo {
    max-height: 52px;
  }

  margin: 0px 36px 0px 0px;
}

#cart-toggle-365707 {
  color: #bdbdbd;

  &:hover {
    color: #212121;
  }
}

// #cart-box-365707 {
//   color: var(--text-color1);
//   background-color: #ffffff;

//   .product_list_widget li:not(:last-child):before,
//   .woocommerce-mini-cart__total:before {
//     background-color: #212121;
//   }

//   .cart-product-title {
//     color: #212121;
//   }

//   .cart_list li .remove {
//     color: var(--text-color1);
//   }

//   .woocommerce-mini-cart__buttons > a {
//     color: #000000;
//     background-color: var(--button-color1);

//     &:hover {
//       color: #ffffff !important;
//       background-color: #212121;
//     }
//   }
// }

#cart-toggle-365707 .cart-contents {
  color: var(--text-color1);

  .cart-title {
    color: var(--color1);
  }
}

// #header-cart-365707 {
//   margin: -1px 0px 0px 0px;
// }

#header-menu-846790 {
  > .menu-item.depth-0 {
    margin-left: 40px;

    &:before,
    &:after {
      background: #e0e0e0;
    }

    &:before {
      left: -20px;
    }

    &:after {
      right: -20px;
    }

    margin-left: 20px !important;
    margin-right: 20px !important;

    > .mi-link {
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link,
    &.current-menu-item > .mi-link,
    &.current-menu-parent > .mi-link,
    &.current-menu-ancestor > .mi-link,
    &.one-page-active > .mi-link {
      color: var(--color1);
    }

    > .mi-link .txt:after {
      border-bottom-color: var(--color1);
    }
  }

  .sub-menu {
    background-color: #ffffff;
  }

  > .menu-item:not(.mm-true) .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }
}

#header-menu-container-846790 {
  margin: 0px 56px 0px 0px;
}

// #et-header-button-749434 {
//   > a {
//     width: 220px;
//     height: 48px;
//     line-height: 47px;
//     padding-top: 0;
//     padding-bottom: 0;
//     font-size: 16px !important;
//     font-weight: 700;
//     letter-spacing: 0px;
//     line-height: 48px !important;
//     text-transform: none;
//     color: #000000;
//     border-radius: 4px;
//   }

//   .icon {
//     font-size: 24px !important;
//     margin-right: 12px !important;
//   }

//   &:hover > a {
//     color: #ffffff;
//   }

//   .regular {
//     background-color: var(--button-color1);
//   }

//   .hover {
//     background-color: #000000;
//   }

//   .et-ink {
//     color: #000000;
//     background-color: #ffffff;
//   }

//   margin: 0px 0px 0px 40px;
// }

#et-header-button-141607 {
  > a {
    width: 282px;
    height: 48px;
    line-height: 47px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 48px !important;
    text-transform: uppercase;
    color: #ffffff;
    border-radius: 4px;
  }

  .icon {
    font-size: 12px !important;
  }

  &:hover > a {
    color: #ffffff;
  }

  .regular {
    background-color: var(--color1);
  }

  .hover {
    background-color: #212121;
  }

  .et-ink {
    color: #ffffff;
    background-color: #ffffff;
  }

  margin: 0px 0px 0px 0px;
}

#header-woo-search-form-772140 {
  .dgwt-wcas-search-submit {
    color: #ffffff;
    background-color: var(--color1);
    box-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #ce550a;
      box-shadow: none;
    }
  }

  width: 620px;

  .dgwt-wcas-search-wrapp {
    height: 48px;
  }

  input.dgwt-wcas-search-input {
    width: 620px;
    color: var(--text-color1);
    background-color: #ffffff;
    border-color: var(--color1);
  }

  margin: -1px 0px 0px 8px;
}

.vc_custom_1558529468053 {
  background-color: #212121 !important;
}

.vc_custom_1553775461826 {
  background-color: var(--color3) !important;
}

.vc_custom_1558529770673 {
  background-color: #f5f5f5 !important;
}

#et-heading-126872 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px -34px 12px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

.mm-378295 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-378295 {
  margin: 4px 8px 0px 0px;
}

.vc_custom_1558707935156 {
  padding-top: 32px !important;
  padding-bottom: 8px !important;
}

#megamenu-2080 {
  width: 900px;
  max-width: 900px;
}

#et-button-349295,
#et-button-323138,
#et-button-624117,
#et-button-575408,
#et-button-155136,
#et-button-980440 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: #f5f5f5;
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1558707830265 {
  padding-top: 16px !important;
}

.vc_custom_1558707807512 {
  padding-bottom: 32px !important;
}

#megamenu-2079 {
  width: 900px;
  max-width: 900px;
}

.vc_custom_1558707459286 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

#megamenu-2078 {
  width: 900px;
  max-width: 900px;
}

.et-separator-895382 .line {
  margin-top: 24px;
  margin-bottom: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
}

#et-heading-950312,
#et-heading-271539,
#et-heading-411869 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #212121;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  a {
    color: #212121;
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-icon-list-77177 .icon,
#et-icon-list-997313 .icon,
#et-icon-list-290168 .icon {
  color: var(--color1);
  border-radius: 0px;
}

#et-button-825721,
#et-button-20399,
#et-button-356251 {
  min-width: 216px;
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1558707359424 {
  padding-top: 8px !important;
  padding-bottom: 32px !important;
}

.vc_custom_1558707285655 {
  margin-top: 24px !important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  background-color: #ffffff !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
  border-radius: 1px !important;
}

.vc_custom_1558707295126,
.vc_custom_1558707306191 {
  margin-top: 24px !important;
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  background-color: #ffffff !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}

#megamenu-2077 {
  width: 900px;
  max-width: 900px;
}

#et-icon-box-container-155466 {
  background-color: #ffffff;
  min-height: 0 !important;
}

#et-icon-box-367200 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-260113 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-614349 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-721856 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-654877 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-187339 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-496015 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-949370 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

.vc_custom_1558706995465 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

#megamenu-2076 {
  width: 900px;
  max-width: 900px;
}

.mm-448987 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-448987 {
  margin: 32px 8px 0px 8px;
}

#megamenu-2075 {
  width: 900px;
  max-width: 900px;
}

.mm-870065 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-870065 {
  margin: 0px 8px 0px 8px;
}

#et-heading-91551 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-386800 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 20px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-40884 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: #000000;
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-960448 {
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: #ffc600;
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1558706296753 {
  padding-top: 32px !important;
  background-image: url(../../images/mm2-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}

.vc_custom_1558706365008 {
  margin-top: 20px !important;
  margin-bottom: 80px !important;
  padding-top: 0px !important;
  padding-left: 240px !important;
}

#megamenu-2074 {
  width: 900px;
  max-width: 900px;
}

.mm-786182 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-786182 {
  margin: 0px 0px 0px 0px;
}

.vc_custom_1558705966192 {
  padding-top: 32px !important;
  background-image: url(../../images/megamenu2-4-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}

#megamenu-2073 {
  width: 900px;
  max-width: 900px;
}

.mm-519050 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-519050 {
  margin: 0px 8px 0px 8px;
}

.vc_custom_1558705364384 {
  padding-top: 24px !important;
}

#megamenu-2072 {
  width: 900px;
  max-width: 900px;
}

.mm-836917 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-836917 {
  margin: 0px 0px 0px 0px;
}

.vc_custom_1558703955955 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

#megamenu-2063 {
  width: 900px;
  max-width: 900px;
}

.mm-23505 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-23505 {
  margin: 32px 8px 0px 8px;
}

#megamenu-880 {
  width: 1200px;
  max-width: 1200px;
}

// .vc_custom_1556196258476 {
//   padding-top: 32px !important;
//   padding-right: 24px !important;
//   padding-bottom: 32px !important;
//   padding-left: 24px !important;
//   background-color: #ffffff !important;
// }

// #megamenu-733 {
//   width: 360px;
//   max-width: 360px;
// }

// #megamenu-tab-726989 {
//   .tabset {
//     background-color: #f5f5f5;
//   }

//   .tabs-container {
//     background-color: #ffffff;
//   }

//   .tabset .tab-item.active .arrow {
//     border-color: transparent #ffffff transparent transparent;
//   }

//   .tab-item {
//     color: #212121;
//     font-size: 16px;
//     font-weight: 600;
//     letter-spacing: 0px;
//     text-transform: none;

//     &:hover,
//     &.active {
//       color: var(--color1);
//     }

//     &:after {
//       background-color: #ffffff;
//       box-shadow: none;
//     }
//   }

//   .tab-content {
//     padding: 0px 0px 0px 0px;
//   }
// }

.mm-258711 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-258711 {
  margin: 0px 8px 0px 8px;
}

.mm-448987 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-448987 {
  margin: 32px 8px 0px 8px;
}

.mm-836917 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-836917 {
  margin: 0px 0px 0px 0px;
}

.mm-786182 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-786182 {
  margin: 0px 0px 0px 0px;
}

.mm-968976 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-968976 {
  margin: 0px 8px 0px 8px;
}

.mm-378295 {
  > .menu-item.depth-0 > .mi-link {
    color: #212121;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;

    &:hover {
      color: var(--color1);
    }

    &:before {
      background-color: #e0e0e0;
    }
  }

  .sub-menu .menu-item {
    .mi-link {
      &:before {
        background-color: var(--text-color1);
      }

      color: var(--text-color1);
      font-weight: 500;
      letter-spacing: 0px;
      text-transform: none;
    }

    &:hover > .mi-link {
      color: var(--color1);
    }
  }

  > .menu-item > .mi-link:before {
    background-color: #e0e0e0;
  }
}

#mm-container-378295 {
  margin: 4px 8px 0px 0px;
}

#et-ad-666542 {
  .background {
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -50px;
    top: 200px;
  }

  padding: 32px 24px 32px 24px;
  margin: 12px 0px 0px 0px;
}

.et-gap-696534 {
  height: 8px;
}

.et-gap-268141 {
  height: 16px;
}

.et-gap-378829 {
  height: 24px;
}

.et-gap-862840 {
  height: 8px;
}

.et-gap-284397 {
  height: 300px;
}

#et-heading-91551 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-386800 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 20px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-40884 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: #000000;
  }

  margin: 0px 0px 8px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-39247,
#et-heading-3606,
#et-heading-559687 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #212121;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;

  a {
    color: #212121;
  }

  margin: 0px 0px 16px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-126872 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px -34px 12px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-189864 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-213149 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-619870 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #000000;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: #000000;
  }

  margin: 0px 0px 0px -2px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-960448 {
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: #ffc600;
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

#et-button-43150,
#et-button-688425,
#et-button-673180 {
  min-width: 225px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: #ffc600;
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

#et-button-349295,
#et-button-323138,
#et-button-624117,
#et-button-575408,
#et-button-155136,
#et-button-218130,
#et-button-980440,
#et-button-794050 {
  min-width: 201px;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: #f5f5f5;
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

#et-button-467660 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #212121;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

#et-icon-box-container-936469 {
  background-color: #ffffff;
  min-height: 0 !important;
}

.et-separator-352792 .line {
  margin-top: 0px;
  margin-bottom: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-style: solid;
}

#et-icon-list-215980 .icon,
#et-icon-list-815659 .icon,
#et-icon-list-488542 .icon {
  color: var(--color1);
  border-radius: 0px;
}

#et-icon-box-152075 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-732083 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-368634 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-769671 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-940426 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-98222 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-34402 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

#et-icon-box-363400 {
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  &:hover {
    background-color: var(--color1);
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  &.link:hover {
    box-shadow: inset 0 0 0 1px var(--color1);
  }

  .et-icon-box-title {
    color: #212121;
  }

  &:hover .et-icon-box-title {
    color: #ffffff;
  }

  .et-icon-box-content {
    color: var(--text-color1);
  }

  &:hover .et-icon-box-content {
    color: #ffffff;
  }

  .et-icon {
    color: var(--color1);
    border-radius: 0px;
  }

  .ghost-icon {
    color: var(--color1);
  }

  &:hover {
    .ghost-icon,
    .et-icon {
      color: #ffffff;
    }
  }

  padding: 24px 24px 24px 24px;
}

.vc_custom_1556032486903 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 8px !important;
}

.vc_custom_1557567416823 {
  padding-right: 24px !important;
  padding-left: 12px !important;
}

.vc_custom_1558537155365 {
  padding-top: 24px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.vc_custom_1556032951115 {
  padding-top: 32px !important;
  background-image: url(../../images/mm1-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}

.vc_custom_1556096552381 {
  padding-top: 0px !important;
  padding-left: 8px !important;
}

.vc_custom_1556096483078 {
  padding-top: 0px !important;
}

.vc_custom_1556033148187 {
  padding-top: 32px !important;
  background-image: url(../../images/mm2-1.jpg) !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
}

.vc_custom_1556096586351 {
  padding-top: 0px !important;
}

.vc_custom_1556096591583 {
  margin-top: 60px !important;
  margin-bottom: 80px !important;
  padding-top: 0px !important;
  padding-left: 270px !important;
}

.vc_custom_1556101384268,
.vc_custom_1558686218213 {
  padding-top: 32px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}

.vc_custom_1556105154636,
.vc_custom_1556105144571,
.vc_custom_1556105304439 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
}

.vc_custom_1556113888436 {
  padding-top: 32px !important;
  padding-right: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
}

.vc_custom_1558537195205 {
  padding-top: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}

.vc_custom_1558537258890 {
  padding-top: 0px !important;
  padding-right: 24px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
}

.vc_custom_1556806829753 {
  padding-top: 32px !important;
  padding-right: 0px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
}

// #megamenu-562 {
//   width: 1200px;
//   max-width: 1200px;
// }

.et-gap-891547 {
  height: 8px;
}

.et-gap-802896 {
  height: 12px;
}

.vc_custom_1558013270805 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  padding-top: 12px !important;
  padding-right: 24px !important;
  padding-bottom: 0px !important;
  padding-left: 24px !important;
  border-left-color: #e0e0e0 !important;
  border-left-style: solid !important;
  border-right-color: #e0e0e0 !important;
  border-right-style: solid !important;
  border-top-color: #e0e0e0 !important;
  border-top-style: solid !important;
  border-bottom-color: #e0e0e0 !important;
  border-bottom-style: solid !important;
  border-radius: 4px !important;
}

.vc_custom_1558013669178 {
  padding-left: 24px !important;
}

#et-ad-57453 {
  .background {
    background-image: url(../../images/banner_back_1-1.jpg);
    background-color: var(--color1);
    background-position: left bottom;
  }

  .et-ad-img {
    left: 12px;
    top: 185px;
  }

  padding: 48px 40px 48px 40px;
  margin: 0px 0px 0px 0px;
}

#et-heading-229599 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: #ffffff;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-213222 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #ffffff;
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: #ffffff;
  }

  margin: 0px 0px 2px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-514354 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;

  a {
    color: #ffffff;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

.et-gap-787046 {
  height: 314px;
}

.et-gap-943262 {
  height: 270px;
}

#et-button-911778 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #000000;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

#et-ad-928278 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -48px;
    top: -12px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-928278 .et-ad-img {
    left: 160px;
    top: -12px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-468490 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557747880457 {
  padding-left: 200px !important;
}

.vc_custom_1557747871950 {
  padding-right: 24px !important;
}

#et-ad-721275 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -20px;
    top: -24px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #et-ad-721275 .et-ad-img {
    top: -12px;
  }
}

@media only screen and (max-width: 479px) {
  #et-ad-721275 .et-ad-img {
    left: 220px;
    top: -12px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-376608 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557747880457 {
  padding-left: 200px !important;
}

.vc_custom_1557747871950 {
  padding-right: 24px !important;
}

#et-ad-890145 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: 25px;
    top: 15px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #et-ad-890145 .et-ad-img {
    left: 35px;
    top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  #et-ad-890145 .et-ad-img {
    left: 150px;
    top: 80px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-138430 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557753152317 {
  padding-left: 260px !important;
}

.vc_custom_1557753145459 {
  padding-right: 24px !important;
}

#et-ad-448273 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: 0px;
    top: 15px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-448273 .et-ad-img {
    left: -120px;
    top: 20px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-138430 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557750380894 {
  padding-left: 200px !important;
}

.vc_custom_1557750372684 {
  padding-right: 24px !important;
}

#et-ad-206743 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: 25px;
    top: 15px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #et-ad-206743 .et-ad-img {
    left: 25px;
    top: 25px;
  }
}

@media only screen and (max-width: 479px) {
  #et-ad-206743 .et-ad-img {
    left: 150px;
    top: 80px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-138430 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557753226247 {
  padding-left: 260px !important;
}

.vc_custom_1557753219244 {
  padding-right: 24px !important;
}

#et-ad-736809 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -40px;
    top: 12px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-736809 .et-ad-img {
    left: 200px;
    top: -10px;
  }
}

.et-gap-921903 {
  height: 16px;
}

.et-gap-316697 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-835415 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-544962 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-121510 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557747384858 {
  padding-left: 200px !important;
}

.vc_custom_1557747415359 {
  padding-right: 24px !important;
}

#et-ad-629105 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -150px;
    top: -24px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  #et-ad-629105 .et-ad-img {
    left: -300px;
    top: -24px;
  }
}

@media only screen and (max-width: 479px) {
  #et-ad-629105 .et-ad-img {
    left: -420px;
    top: -32px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-268438 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-788616 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557745320933 {
  padding-left: 350px !important;
}

.vc_custom_1557744417001 {
  padding-right: 24px !important;
}

#et-ad-90449 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -120px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-90449 .et-ad-img {
    left: 140px;
    top: 80px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-561293 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557748075520 {
  padding-left: 200px !important;
}

.vc_custom_1557748066382 {
  padding-right: 24px !important;
}

#et-ad-203879 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -40px;
    top: -24px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-203879 .et-ad-img {
    left: 220px;
    top: -12px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-899091 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557747880457 {
  padding-left: 200px !important;
}

.vc_custom_1557747871950 {
  padding-right: 24px !important;
}

#et-ad-360815 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -70px;
    top: 12px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-360815 .et-ad-img {
    left: 230px;
    top: -12px;
  }
}

.et-gap-219039 {
  height: 16px;
}

.et-gap-217795 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-855518 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-312565 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-98351 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557747754080 {
  padding-left: 200px !important;
}

.vc_custom_1557747746219 {
  padding-right: 24px !important;
}

#et-ad-863298 {
  border-radius: 8px;

  .background {
    background-image: url(../../images/slider_pattern_white.png);
    background-color: #f5f5f5;
    background-position: left top;
  }

  .et-ad-img {
    left: -40px;
    top: -12px;
  }

  padding: 32px 24px 24px 24px;
  margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 479px) {
  #et-ad-863298 .et-ad-img {
    left: -160px;
    top: -24px;
  }
}

.et-gap-921903 {
  height: 16px;
}

.et-gap-316697 {
  height: 24px;
}

.et-gap-70546 {
  height: 12px;
}

#et-heading-835415 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-409461 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--color1);
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-transform: uppercase;

  a {
    color: var(--color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-button-121510 {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000000;
  border-radius: 4px;

  &:hover {
    color: #ffffff;
  }

  .regular {
    background-color: var(--button-color1);
  }

  .hover {
    background-color: #d52b27;
  }

  .et-ink {
    color: #000000;
    background-color: #ffffff;
  }
}

.vc_custom_1557572368039 {
  padding-left: 200px !important;
}

.vc_custom_1557572387142 {
  padding-right: 24px !important;
}

#et-breadcrumbs-394423 {
  background-color: transparent;
  padding: 0;
  color: var(--text-color1);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;

  > *:before {
    color: var(--color1);
  }
}

// .vc_custom_1554986226802 {
//   padding-top: 24px !important;
//   padding-bottom: 24px !important;
//   background-color: #ffffff !important;
// }

.et-icon-680651 {
  color: #ffffff;
  border-radius: 0px;
}

.et-gap-439281,
.et-gap-308481 {
  height: 12px;
}

.et-gap-883750 {
  height: 24px;
}

.et-gap-536909 {
  height: 8px;
}

.et-gap-663926,
.et-gap-913975 {
  height: 32px;
}

.et-gap-580697,
.et-gap-299018 {
  height: 16px;
}

.et-gap-688443,
.et-gap-409036 {
  height: 42px;
}

#et-heading-58211 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;

  a {
    color: #ffffff;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-375874 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #ffffff;
  font-weight: 500;
  line-height: 26px;
  text-transform: none;

  a {
    color: #ffffff;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-123899 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #000000;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;

  a {
    color: #000000;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-126946 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: var(--text-color1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;

  a {
    color: var(--text-color1);
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-299385 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #bdbdbd;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;

  a {
    color: #bdbdbd;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-heading-831981 {
  .text-wrapper {
    background-color: transparent;
    padding: 0;
  }

  color: #212121;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;

  a {
    color: #212121;
  }

  margin: 0px 0px 0px 0px;

  .text-wrapper {
    padding: 0px 0px 0px 0px;
  }
}

#et-mailchimp-576420 {
  .field {
    color: var(--text-color1);
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }

  ::placeholder {
    color: var(--text-color1);
  }

  .field:focus {
    color: var(--text-color1);
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
  }

  .send-div .button,
  .sending {
    color: #000000;
    background-color: var(--button-color1);
  }

  .send-div {
    .button:hover,
    &:hover .sending {
      color: #ffffff;
      background-color: #212121 !important;
    }
  }
}

#et-icon-box-893709 {
  &:hover,
  &.link:hover {
  }

  .et-icon-box-title,
  .et-icon-box-content {
    color: var(--text-color1);
  }

  .et-icon {
    color: #ffd800;
    border-radius: 0px;
  }

  .ghost-icon {
    color: #ffd800;
  }

  &:hover .et-icon {
  }

  padding: 0px 0px 0px 0px;
}

#et-social-links-397572 a {
  color: #9a9a9a;
  background-color: transparent;
  box-shadow: none;
  margin-right: 0;

  &:hover {
    color: #212121;
    background-color: transparent;
    box-shadow: none;
  }
}

#et-icon-list-77177 .icon,
#et-icon-list-997313 .icon,
#et-icon-list-501253 .icon {
  color: var(--color1);
  border-radius: 0px;
}

.vc_custom_1558615059402 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  background: var(--color1) url(../../images/map_dark-1.png) !important;
  background-blend-mode: soft-light;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.vc_custom_1559292623224 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
  background: var(--color3) url(../../images/slider_pattern_white.png) !important;
  background-position: 0 0 !important;
  background-repeat: repeat !important;
}

.vc_custom_1559291692163 {
  padding-top: 24px !important;
  padding-bottom: 16px !important;
  background-color: var(--color2) !important;
}

.vc_custom_1559292758492 {
  padding-right: 32px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

@media only screen and (max-width: 479px) {
  .list {
    #loop-posts .post .post-title,
    .et-shortcode-posts .post .post-title {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .post-title-section .post-title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .list {
    #loop-posts .post .post-title,
    .et-shortcode-posts .post .post-title {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .full #loop-posts .post .post-title {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .background-position-right-center-tablet .background {
    background-position: 0 center !important;
  }

  .category-icon-list.et-icon-list {
    display: inline-block;

    + .et-button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .medium.grid {
    #loop-posts .post .post-title,
    .et-shortcode-posts .post .post-title {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .background-position-right-center-tablet .background {
    background-position: 150% center !important;
  }

  .category-icon-list.et-icon-list {
    display: inline-block;

    + .et-button {
      display: block;
      max-width: 216px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  .list {
    #loop-posts .post .post-title,
    .et-shortcode-posts .post .post-title {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .full #loop-posts .post .post-title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .post-title-section .post-title,
  .full #loop-posts .post .post-title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .medium.grid .blog-content #loop-posts .post .post-title {
    font-size: 20px;
    line-height: 28px;
  }

  #yith-quick-view-modal .yith-wcqv-wrapper {
    width: 960px !important;
    height: 480px !important;
    margin-left: -480px !important;
    margin-top: -240px !important;
  }

  #yith-quick-view-content .summary {
    height: 480px !important;
  }

  .background-position-right-center-tablet .background {
    background-position: 120% center !important;
  }

  .logo-size-normal .vc_column-inner {
    background-position: left !important;
  }

  .category-icon-list.et-icon-list {
    display: inline-block;

    + .et-button {
      display: block;
      max-width: 216px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .homepage-3-banner-group > .container {
    max-width: 60%;
  }
}

@media only screen and (max-width: 1279px) {
  .margin-top-1-tablet {
    margin-top: -1px;
  }
}

@media only screen and (min-width: 1280px) {
  #yith-quick-view-modal .yith-wcqv-wrapper {
    width: 960px !important;
    height: 480px !important;
    margin-left: -480px !important;
    margin-top: -240px !important;
  }

  #yith-quick-view-content .summary {
    height: 480px !important;
  }

  #yith-quick-view-modal .yith-wcqv-wrapper {
    width: 960px !important;
    height: 480px !important;
    margin-left: -480px !important;
    margin-top: -240px !important;
  }

  .homepage-3-banner-group
    > .container
    > .wpb_column:last-child
    > .vc_column-inner {
    margin-left: -1px;
  }

  .hot-deals-of-the-day .woo-single-product {
    height: 543px;
  }
}
