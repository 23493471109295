// $color1: var(--color1);
// $color2: #ffd800;
// box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 10px 0px;

$color1: #c81c2e;
$color2: #313131;
$color3: #4e4e4e;

:root {
  --color1: #c81c2e;
  --color-hover1: #a41726;
  --color2: #313131;
  --color-hover2: #4e4e4e;
  --color3: #4e4e4e;
  --color-hover3: #737373;
  --color4: #737373;
  --color-hover4: #5f5f5f;
  --color5: #777777;
  --color-hover5: #575757;
  --cmp-color1: #777777;
  --cmp-hover-color1: #575757;
  --text-color1: #ffffff;
  --text-color-hover1: #ffffff;
  --text-color2: #313131;
  --text-color-hover2: #4e4e4e;
  --text-color3: #ffffff;
  --button-color1: #c81c2e;
  --button-color-hover1: #a41726;
}
