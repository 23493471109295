.container {
  padding-top: 48px !important;

  .title {
    color: var(--color1);
    font-weight: 700;
    line-height: 24px;
    text-transform: none;
    margin: 0px 0px 8px 0px;
    :global(.text-wrapper) {
      background-color: transparent;
      padding: 0;
    }
    a {
      color: var(--color1);
    }
  }

  .description {
    color: var(--text-color1);
    font-weight: 500;
    line-height: 24px;
    text-transform: none;
    margin: 0px 0px 0px 0px;
    :global(.text-wrapper) {
      background-color: transparent;
      padding: 0;
    }
    a {
      color: var(--text-color1);
    }
  }
}
