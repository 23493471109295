.dgwt-wcas-block-scroll {
  overflow: hidden;
}
.dgwt-wcas-search-wrapp {
  position: relative;
  display: block;
  color: #444;
  min-width: 230px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  .product-title {
    color: #333;
    display: block;
    line-height: 110%;
    margin: 0;
  }
}
.dgwt-wcas-tpd-image {
  img {
    margin: 0 15px 0 0 !important;
    display: block;
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  width: 60px;
  margin-right: 15px;
  width: 70px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rtl {
  .dgwt-wcas-tpd-image {
    img {
      margin: 0 0 0 15px !important;
    }
    float: right;
    margin-right: 0;
    margin-left: 15px;
  }
  .dgwt-wcas-st-breadcrumbs {
    padding-right: 0;
    padding-left: 10px;
    text-align: right;
  }
  .dgwt-wcas-content-wrapp {
    padding-left: 0;
    padding-right: 10px;
  }
  .dgwt-wcas-suggestion {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
  }
  .dgwt-wcas-details-wrapp {
    border-left: 1px solid #ddd;
    border-right: none;
  }
  .dgwt-wcas-pd-rating {
    .star-rating {
      float: right;
      margin-left: 5px;
      margin-right: 0;
    }
  }
  .dgwt-wcas-details-title-tax {
    margin: 0 0 0 3px;
    float: right;
  }
  .dgwt-wcas-pd-image {
    float: right;
    margin-right: 0;
    margin-left: 15px;
  }
  .dgwt-wcas-pd-rest {
    float: right;
    max-width: 190px;
  }
  .dgwt-wcas-tpd-rest {
    float: right;
    max-width: 190px;
  }
  input[type='search'].dgwt-wcas-search-input {
    text-align: right;
  }
  .dgwt-wcas-preloader {
    right: auto;
    left: 5px;
  }
  .dgwt-wcas-sf-wrapp {
    button.dgwt-wcas-search-submit {
      left: 0;
      right: auto;
    }
    .dgwt-wcas-search-submit {
      &:before {
        left: auto;
        right: -6px;
        border-width: 8px 0 8px 8px;
        border-style: solid none solid solid;
      }
    }
  }
  .dgwt-wcas-no-submit {
    .dgwt-wcas-ico-magnifier {
      left: auto;
      right: 12px;
    }
    .dgwt-wcas-sf-wrapp {
      input[type='search'].dgwt-wcas-search-input {
        padding: 10px 40px 10px 15px;
      }
    }
  }
  .dgwt-wcas-om-bar {
    .dgwt-wcas-om-return {
      svg {
        left: 11px;
        top: 9px;
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    .dgwt-wcas-has-submit-off {
      .dgwt-wcas-preloader {
        left: 4px !important;
        right: auto !important;
      }
    }
  }
}
.dgwt-wcas-preloader-wrapp {
  img {
    margin: 10px auto 0 auto;
    display: block;
    max-width: 200px;
  }
  img.dgwt-wcas-placeholder-preloader {
    display: block;
    width: 100%;
    height: auto;
    max-width: 300px;
    margin: 0;
  }
}
.dgwt-wcas-suggestions-wrapp {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-top: none;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;
}
.dgwt-wcas-suggestions-wrapp.dgwt-wcas-suggestions-wrapp--top {
  border: 1px solid #ddd;
  border-bottom: none;
}
.dgwt-wcas-is-detail-box {
  .dgwt-wcas-suggestions-wrapp {
    max-width: 550px;
  }
}
.dgwt-wcas-full-width.dgwt-wcas-is-detail-box {
  .dgwt-wcas-suggestion {
    padding: 8px 15px;
  }
}
.dgwt-wcas-details-left {
  .dgwt-wcas-suggestions-wrapp {
    border-radius: 0 0 5px 0;
    border-left-color: #eee;
  }
  .dgwt-wcas-details-wrapp {
    border-right: none;
    border-left: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
  }
}
.dgwt-wcas-details-right {
  .dgwt-wcas-suggestions-wrapp {
    border-radius: 0 0 0 5px;
    border-right-color: #eee;
  }
  .dgwt-wcas-details-wrapp {
    border-left: none;
    border-right: 1px solid #ddd;
    border-radius: 0 5px 5px 0;
  }
}
.dgwt-wcas-suggestion {
  cursor: pointer;
  font-size: 12px;
  line-height: 110%;
  text-align: left;
  padding: 7px 10px;
  position: relative;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  resize: vertical;
  &:after {
    clear: both;
    display: block;
    content: '';
  }
  strong {
    font-weight: 700;
  }
}
.dgwt-wcas-suggestion.dgwt-wcas-suggestion-more {
  justify-content: center;
  min-height: 30px;
  text-transform: uppercase;
  .dgwt-wcas-st-more {
    padding: 0 10px;
    text-align: center;
  }
}
.dgwt-wcas-suggestion.dgwt-wcas-suggestion-no-border-bottom {
  border-bottom: none;
}
.dgwt-wcas-suggestion.dgwt-wcas-nores {
  cursor: default;
  opacity: 0.5;
}
.dgwt-wcas-has-img {
  .dgwt-wcas-suggestion.dgwt-wcas-suggestion-product {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 60px;
  }
  .dgwt-wcas-sp {
    > * {
      display: block;
    }
  }
  .dgwt-wcas-suggestion[data-taxonomy='product_cat'] {
    padding-left: 15px;
    min-height: 0;
    .dgwt-wcas-st {
      padding-left: 0;
    }
  }
  .dgwt-wcas-suggestion[data-taxonomy='product_tag'] {
    padding-left: 15px;
    min-height: 0;
    .dgwt-wcas-st {
      padding-left: 0;
    }
  }
}
.dgwt-wcas-is-details {
  .dgwt-wcas-has-img {
    .dgwt-wcas-suggestion.dgwt-wcas-suggestion-more {
      min-height: 30px;
      text-transform: uppercase;
    }
    .dgwt-wcas-suggestion.dgwt-wcas-suggestion-product {
      min-height: 40px;
    }
  }
  .dgwt-wcas-suggestions-wrapp {
    min-height: 340px;
    overflow: visible;
  }
  .dgwt-wcas-si {
    width: 30px;
    max-width: 100%;
    box-sizing: border-box;
  }
  .dgwt-wcas-sp {
    display: flex;
    top: 0;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    resize: vertical;
  }
  .dgwt-wcas-sd {
    font-size: 11px;
    line-height: 100%;
  }
  .dgwt-wcas-has-headings {
    .dgwt-wcas-suggestion.dgwt-wcas-suggestion-tax {
      min-height: 30px;
    }
  }
}
.dgwt-wcas-st {
  display: block;
  color: #444;
  font-size: 15px;
  width: 100%;
  > span {
    display: inline-block;
    vertical-align: middle;
  }
  > span.dgwt-wcas-st--direct-headline {
    display: block;
    font-style: italic;
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0.4;
    line-height: 10px;
    margin-bottom: 1px;
    letter-spacing: 2px;
  }
}
.dgwt-wcas-has-sku {
  .dgwt-wcas-st-title {
    margin-right: 6px;
  }
}
.dgwt-wcas-st-breadcrumbs {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 80%;
  text-align: left;
  opacity: 0.6;
  width: 100%;
  padding-right: 10px;
}
.dgwt-wcas-sku {
  font-size: 80%;
}
.dgwt-wcas-si {
  width: 50px;
  display: block;
  height: 100%;
  img {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    display: block;
    margin: auto;
    padding: 2px;
    height: auto;
    width: auto;
    max-height: 90%;
    max-width: 100%;
  }
}
.dgwt-wcas-content-wrapp {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  resize: vertical;
  padding-left: 10px;
}
.dgwt-wcas-sp {
  color: #555;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  line-height: 4px;
  padding-left: 10px;
  * {
    line-height: 130%;
  }
  del {
    opacity: 0.5;
    font-size: 12px;
  }
}
.dgwt-wcas-search-form {
  margin: 0;
  padding: 0;
}
.dgwt-wcas-sd {
  color: #777;
  display: block;
  line-height: 14px;
  width: 100%;
  margin-top: 5px;
  font-size: 0.8em;
}
.dgwt-wcas-suggestion-selected {
  background-color: #eee;
  &:first-child {
    border-top: none;
  }
}
.dgwt-wcas-details-wrapp {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-top: none;
  border-left: none;
  padding: 0;
  width: 300px;
  box-sizing: border-box;
  z-index: 100;
  min-height: 340px;
  .dgwt-wcas-pd-title {
    margin-bottom: 8px;
  }
}
.dgwt-wcas-details-outside {
  .dgwt-wcas-details-wrapp {
    margin-top: -1px;
    border-top: 1px solid #ddd;
  }
}
.dgwt-wcas-details-outside.dgwt-wcas-is-details {
  .dgwt-wcas-suggestions-wrapp {
    margin-top: -1px;
    border-top: 1px solid #ddd;
  }
}
.dgwt-wcas-details-notfit {
  .dgwt-wcas-details-wrapp {
    display: none;
  }
}
.dgwt-wcas-full-width {
  .dgwt-wcas-suggestions-wrapp {
    border-radius: 0 0 0 5px;
  }
  .dgwt-wcas-details-wrapp {
    border-radius: 0 0 5px 0;
  }
}
.rtl.dgwt-wcas-full-width {
  .dgwt-wcas-suggestions-wrapp {
    border-radius: 0 0 5px 0;
  }
  .dgwt-wcas-details-wrapp {
    border-radius: 0 0 0 5px;
  }
}
.dgwt-wcas-details-inner {
  padding: 15px 10px;
  height: 100%;
  .added_to_cart {
    display: block;
  }
}
.woocommerce {
  .dgwt-wcas-pd-rating {
    .star-rating {
      float: left;
      margin-right: 5px;
      font-size: 12px;
      color: #ccae72;
    }
  }
}
.dgwt-wcas-datails-title {
  border-bottom: 1px dashed #eee;
  display: block;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px dashed #eee;
  display: block;
  margin: 5px 0 15px 0;
  padding-bottom: 5px;
}
.dgwt-wcas-pd-title {
  border-bottom: 1px dashed #eee;
  margin-bottom: 15px;
  padding-bottom: 5px;
  display: block;
  color: #202020;
  text-decoration: none;
  border-bottom: 1px dashed #eee;
  margin-bottom: 15px;
  padding-bottom: 5px;
  display: block;
  text-decoration: none;
  .product-title {
    font-weight: 900;
    color: #202020;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin: 0;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
  }
}
.dgwt-wcas-details-title-tax {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -1px;
  margin-right: 3px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -1px;
  margin-right: 3px;
}
.dgwt-wcas-tax-product-details {
  margin-bottom: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
}
.dgwt-wcas-pd-rest {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
  .product-title {
    display: block;
    line-height: 100%;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
.dgwt-wcas-tpd-rest {
  max-width: 200px;
  .product-title {
    display: block;
    line-height: 100%;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 145px;
  }
}
.dgwt-wcas-suggestion-nores {
  pointer-events: none;
  opacity: 0.45;
}
.dgwt-wcas-pd-price {
  font-size: 14px;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 145px;
  color: #777;
  margin-top: 3px;
  font-weight: lighter;
  del {
    .amount {
      font-weight: lighter;
      background: 0 0;
      font-size: 90%;
      opacity: 0.6;
    }
  }
  ins {
    background: 0 0;
    text-decoration: none;
  }
}
.dgwt-wcas-pd-rating {
  font-size: 13px;
  line-height: 13px;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
}
.dgwt-wcas-pd-review {
  color: #777;
  font-size: 11px;
  line-height: 11px;
}
.dgwt-wcas-pd-details {
  display: flex;
  justify-content: left;
}
.dgwt-wcas-pd-image {
  width: 70px;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
input[type='search'].dgwt-wcas-search-input {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  -webkit-appearance: textfield;
  text-align: left;
}
input[type='text'].dgwt-cas-search-input {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  -webkit-appearance: textfield;
  text-align: left;
}
[type='search'].dgwt-wcas-search-input {
  &::-ms-clear {
    display: none;
  }
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
}
.dgwt-wcas-pd-desc {
  font-size: 13px;
  line-height: 157%;
  margin: 12px 0;
}
.dgwt-wcas-pd-addtc {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > .quantity {
    margin-right: 10px;
  }
  .add_to_cart_button {
    margin: 0;
  }
  .add_to_cart_inline {
    margin: 0;
  }
}
button.dgwt-wcas-search-submit {
  position: relative;
}
input[type='submit'].dgwt-wcas-search-submit {
  position: relative;
}
.dgwt-wcas-ico-magnifier {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  height: 65%;
  display: block;
  fill: #fff;
}
.dgwt-wcas-preloader {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: auto 44%;
}
.dgwt-wcas-inner-preloader {
  background-image: url(../img/preloader.png);
  background-repeat: no-repeat;
  background-position: right 13px center;
  background-size: auto 22px;
}
.dgwt-wcas-close {
  &:not(.dgwt-wcas-inner-preloader) {
    cursor: pointer;
    background-image: url(../img/close.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: auto 18px;
    opacity: 0.6;
    transition: all 160ms ease-in-out;
    color: var(--text-color1);
    right: 5px;
  }
  &:hover {
    opacity: 0.3;
  }
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}
.dgwt-wcas-sf-wrapp {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:before {
    content: '';
    display: table;
  }
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: 0 0;
  input[type='search'].dgwt-wcas-search-input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 100%;
    padding: 10px 15px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-appearance: none;
    box-sizing: border-box;
    &:focus {
      outline: 0;
      background: #fff;
      box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.06);
    }
    &::placeholder {
      color: #999;
      font-weight: 400;
      font-style: italic;
      line-height: normal;
    }
    &::-webkit-input-placeholder {
      color: #999;
      font-weight: 400;
      font-style: italic;
      line-height: normal;
    }
    &:-moz-placeholder {
      color: #999;
      font-weight: 400;
      font-style: italic;
      line-height: normal;
    }
    &::-moz-placeholder {
      color: #999;
      font-weight: 400;
      font-style: italic;
      line-height: normal;
    }
    &:-ms-input-placeholder {
      color: #999;
      font-weight: 400;
      font-style: italic;
      line-height: normal;
    }
  }
  button.dgwt-wcas-search-submit {
    overflow: visible;
    position: absolute;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 40px;
    min-width: 50px;
    right: 0;
    left: auto;
    top: 0;
    bottom: auto;
    padding: 0 15px;
    color: #fff;
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    text-transform: uppercase;
    background-color: #333;
    border-radius: 0 2px 2px 0;
    -webkit-border-radius: 0 2px 2px 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
  }
  .dgwt-wcas-search-submit {
    &:hover {
      opacity: 0.7;
      &:before {
        border-right-color: #333;
      }
    }
    &:active {
      opacity: 0.7;
      outline: 0;
      &:before {
        border-right-color: #333;
      }
    }
    &:focus {
      opacity: 0.7;
      outline: 0;
      &:before {
        border-right-color: #333;
      }
    }
    &:before {
      content: '';
      position: absolute;
      border-width: 8px 8px 8px 0;
      border-style: solid solid solid none;
      border-color: transparent #333 transparent;
      top: 12px;
      left: -6px;
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
    }
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
}
.dgwt-wcas-open {
  .dgwt-wcas-sf-wrapp {
    input[type='search'].dgwt-wcas-search-input {
      border-radius: 3px 3px 0 0;
    }
    .dgwt-wcas-search-submit {
      border-radius: 0 2px 0 0;
    }
  }
}
.dgwt-wcas-open.dgwt-wcas-nores.dgwt-wcas-details-outside {
  .dgwt-wcas-details-wrapp {
    display: none !important;
  }
  .dgwt-wcas-suggestions-wrapp {
    min-height: 0 !important;
    border-radius: 0 0 5px 5px;
    border-right-color: #ddd;
  }
}
.dgwt-wcas-open.dgwt-wcas-nores.dgwt-wcas-full-width {
  .dgwt-wcas-details-wrapp {
    min-height: 41px !important;
  }
  .dgwt-wcas-suggestions-wrapp {
    min-height: 41px !important;
  }
  &:not(.rtl) {
    .dgwt-wcas-suggestions-wrapp {
      border-right-color: transparent;
    }
  }
}
.dgwt-wcas-open.dgwt-wcas-nores {
  .dgwt-wcas-details-wrapp {
    .dgwt-wcas-preloader-wrapp {
      display: none !important;
    }
  }
}
.rtl.dgwt-wcas-open.dgwt-wcas-nores.dgwt-wcas-full-width {
  .dgwt-wcas-suggestions-wrapp {
    border-left-color: transparent;
  }
}
.dgwt-wcas-has-desc {
  .dgwt-wcas-sp {
    > * {
      display: block;
    }
  }
}
body {
  &:not(.dgwt-wcas-is-details) {
    .dgwt-wcas-suggestion {
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .dgwt-wcas-content-wrapp {
    .rood {
      display: none;
    }
    .zwave {
      display: none;
    }
  }
}
.dgwt-wcas-no-submit {
  .dgwt-wcas-ico-magnifier {
    height: 50%;
    max-height: 20px;
    width: 100%;
    max-width: 20px;
    left: 12px;
    right: auto;
    opacity: 0.5;
    fill: #000;
  }
  .dgwt-wcas-sf-wrapp {
    input[type='search'].dgwt-wcas-search-input {
      padding: 10px 15px 10px 40px;
    }
  }
}
.dgwt-wcas-overlay-mobile-on {
  overflow: hidden;
  .dgwt-wcas-suggestions-wrapp {
    left: 0 !important;
    top: 45px !important;
    width: 100% !important;
    height: calc(100% - 45px) !important;
    position: absolute;
    overflow-y: auto;
    border: none;
  }
  .dgwt-wcas-enable-mobile-form {
    display: none;
  }
}
.dgwt-wcas-om-bar {
  display: flex;
  height: 45px;
  width: 100%;
  .dgwt-wcas-om-return {
    width: 45px;
    height: 100%;
    display: block;
    background: #ccc;
    cursor: pointer;
    position: relative;
    svg {
      width: 23px;
      height: 23px;
      position: absolute;
      left: 12px;
      top: 12px;
    }
  }
  .dgwt-wcas-has-submit-off {
    .dgwt-wcas-preloader {
      right: 0 !important;
    }
  }
  .dgwt-wcas-sf-wrapp {
    button.dgwt-wcas-search-submit {
      display: none;
    }
  }
  .dgwt-wcas-search-wrapp-mobile {
    width: calc(100% - 45px);
    max-width: none;
  }
}
.dgwt-wcas-overlay-mobile {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  * {
    box-sizing: border-box;
  }
}
.dgwt-wcas-search-wrapp-mobile {
  input[type='search'].dgwt-wcas-search-input {
    height: 45px !important;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    font-size: 16px !important;
    &:focus {
      font-size: 16px !important;
    }
  }
}
.dgwt-wcas-enable-mobile-form {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dgwt-wcas-overlay-mobile-on.dgwt-wcas-is-ios {
  .dgwt-wcas-suggestions-wrapp {
    height: calc(100% - 45px) !important;
    max-height: none !important;
    height: auto !important;
  }
  overflow: visible;
  overflow-x: hidden;
  &:not(.dgwt-wcas-theme-flatsome) > :not(.dgwt-wcas-overlay-mobile) {
    display: none;
  }
}
.dgwt-wcas-has-headings {
  .dgwt-wcas-suggestion {
    border-bottom: none;
  }
  .dgwt-wcas-st {
    > span.dgwt-wcas-st--direct-headline {
      display: none;
    }
  }
  .dgwt-wcas-suggestion-headline {
    pointer-events: none;
    display: block;
    font-weight: 500;
    white-space: nowrap;
    .dgwt-wcas-st {
      border-bottom: 1px solid #ddd;
      padding-bottom: 3px;
      text-transform: uppercase;
      margin-top: 16px;
      font-size: 12px;
    }
  }
  .dgwt-wcas-suggestion.dgwt-wcas-suggestion-headline {
    border-bottom: none;
  }
  .dgwt-wcas-suggestion.dgwt-wcas-suggestion-tax {
    border-bottom: none;
    &:hover {
      background: 0 0;
      text-decoration: underline;
    }
  }
  .dgwt-wcas-suggestion-tax {
    .dgwt-wcas-st {
      padding: 0;
    }
  }
  .dgwt-wcas-suggestion.dgwt-wcas-suggestion-tax.dgwt-wcas-suggestion-selected {
    background: 0 0;
    text-decoration: underline;
  }
}
@media all and (-ms-high-contrast: none) {
  .dgwt-wcas-inner-preloader {
    background-image: url(../img/preloader.gif);
  }
}
@media (-ms-high-contrast: active) {
  .dgwt-wcas-inner-preloader {
    background-image: url(../img/preloader.gif);
  }
}
@media screen and (max-width: 992px) {
  .dgwt-wcas-is-detail-box {
    .dgwt-wcas-suggestions-wrapp {
      max-width: none;
    }
  }
}
